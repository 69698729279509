import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useLocation, useParams } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import {
  Button,
  Heading,
  Grid,
  GridItem,
  Stack,
  Text,
  VStack,
  Image,
  HStack,
  Box,
  Link,
} from "@chakra-ui/react";

import { loggedInUserState } from "../../atoms/UserAthomState";

import { IHark } from "../../@types/IHark";
import { getHarkById } from "../../api/hark";

import Card from "../../components/Card";
import StatusTag from "../../components/StatusTag";
import StatusProgressBar from "./StatusProgressBar";
import TrackingSvg from "../../assets/svg/Tracking.svg";
import { convertTZ, DdMonthYYYY, HHMM } from "../../utils/dateFormatter";

export default function HarksPage() {
  const { id } = useParams();
  const user = useRecoilValue(loggedInUserState);
  const location = useLocation();
  // @ts-ignore
  const harkData = location.state?.data;
  const [hark, setHark] = useState<IHark>(harkData || null);
  const [, setLoading] = useState(false);

  const loadHark = async () => {
    try {
      setLoading(true);
      const hark = await getHarkById(id as string);
      setHark(hark);
      setLoading(false);
    } catch (err) {
      // Todo! Handle Error
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    loadHark();
  }, [user]);

  return hark ? (
    <Stack
      width="full"
      overflow="scroll"
      height="full"
      px={{ base: "5", md: "16" }}
      display="flex"
      justifyContent="center"
      gap={{ base: 3, md: 6 }}
    >
      <Stack gap={{ base: 3, md: 6 }} maxWidth="800px">
        <Button
          as={Link}
          to="../"
          colorScheme="brand"
          variant="ghost"
          leftIcon={<FiArrowLeft />}
          color="brand.700"
          width="fit-content"
        >
          Back
        </Button>
        <Heading size="lg">Tracking</Heading>
      </Stack>

      <Grid
        templateColumns={{ base: "repeat(1, 2fr)", md: "repeat(2, 1fr)" }}
        gap={4}
      >
        {/* Colum 1 */}
        <GridItem>
          <VStack width="full" gap={5}>
            <Card>
              <Stack
                width="full"
                direction={["column", "row"]}
                alignItems="start"
              >
                <Image rounded="md" w="40" src={hark.thumbnail_url} />
                <Stack width="full" gap={5}>
                  <Text
                    textTransform="capitalize"
                    fontWeight="bold"
                    color="brand.800"
                  >
                    {hark?.product_name}
                  </Text>
                  <StatusProgressBar status={hark.status} />
                </Stack>
              </Stack>
            </Card>

            {/* Additonal files */}
            {hark.additional_files.length && (
              <Stack width="full">
                <Heading size="md" color="primary.700" as="h2">
                  {hark.additional_files.length} attached files
                </Heading>
                <Card>
                  <Stack width="100%" gap={2}>
                    {hark.additional_files.map((file) => (
                      <Box
                        display="flex"
                        overflow="scroll"
                        width="100%"
                        justifyContent="space-between"
                        color="gray"
                        key={file.url}
                        alignItems="start"
                      >
                        <Text
                          maxW="50%"
                          overflowX="hidden"
                          textOverflow="clip"
                          overflowWrap="break-word"
                        >
                          {file.name}
                        </Text>
                        <Button
                          size="sm"
                          width="fit-content"
                          colorScheme="primary"
                          fontWeight="normal"
                          as={Link}
                          href={file.url}
                          variant="ghost"
                        >
                          View
                        </Button>
                      </Box>
                    ))}
                  </Stack>
                </Card>
              </Stack>
            )}
          </VStack>
        </GridItem>

        {/* Colum 2 */}
        <GridItem>
          <VStack width="full">
            <Card>
              <Stack gap={3} width="full">
                <HStack
                  width="full"
                  alignItems="center"
                  justify="space-between"
                >
                  <Heading as="h3" size="sm">
                    Status
                  </Heading>
                  <StatusTag status={hark.status} />
                </HStack>
              </Stack>
            </Card>

            <Card>
              <Stack gap={5}>
                {" "}
                <Grid
                  color="gray"
                  templateColumns={{
                    base: "repeat(1, 2fr)",
                    md: "repeat(2, 1fr)",
                  }}
                  gap={4}
                >
                  {/* Colum 1 */}
                  <GridItem>
                    <Stack gap={0}>
                      <Text fontWeight="bold">
                        {" "}
                        {DdMonthYYYY(convertTZ(hark.createdAt))}
                      </Text>
                      <Text> at {HHMM(convertTZ(hark.createdAt))}</Text>
                    </Stack>
                  </GridItem>
                  <GridItem>
                    <Stack gap={2} alignItems="start" direction="row">
                      <Image src={TrackingSvg} />
                      <Text>Hark Submitted</Text>
                    </Stack>
                  </GridItem>
                </Grid>
                {hark.timeline.map((timeline) => (
                  <Grid
                    key={timeline.date}
                    color="gray"
                    templateColumns={{
                      base: "repeat(1, 2fr)",
                      md: "repeat(2, 1fr)",
                    }}
                    gap={4}
                  >
                    {/* Colum 1 */}
                    <GridItem>
                      <Stack gap={0}>
                        <Text fontWeight="bold">
                          {DdMonthYYYY(convertTZ(timeline.date))}
                        </Text>
                        <Text> at {HHMM(convertTZ(timeline.date))}</Text>
                      </Stack>
                    </GridItem>
                    <GridItem>
                      <Stack gap={2} alignItems="start" direction="row">
                        <Image src={TrackingSvg} />
                        {timeline.assignee && (
                          <Text>Request assigned to {timeline.assignee}</Text>
                        )}
                        {timeline.status && (
                          <Text>Request Status: {timeline.status}</Text>
                        )}
                      </Stack>
                    </GridItem>
                  </Grid>
                ))}
              </Stack>
            </Card>
          </VStack>
        </GridItem>
      </Grid>
    </Stack>
  ) : (
    <Box>Loading</Box>
  );
}

import { HStack, Box, Text, IconButton, Icon } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { FaRegFile } from "react-icons/fa";
import { FiTrash2, FiFilm, FiImage, FiVolume2 } from "react-icons/fi";
import { formatBytes } from "../../../utils/fileSizeFormatter";

const fileIcons: { [key: string]: IconType } = {
  audio: FiVolume2,
  video: FiFilm,
  image: FiImage,
  other: FaRegFile,
};

type UploadedFileProps = {
  fileName: string;
  size: number;
  type: string;
  onDelete: () => void;
};

const UploadedFile = ({
  fileName,
  size,
  type,
  onDelete,
}: UploadedFileProps) => {
  const fileType = type?.split("/")[0];
  const fileICon = fileIcons[fileType] ? fileIcons[fileType] : fileIcons.other;
  return (
    <HStack
      p="2"
      bg="white"
      alignItems="start"
      justifyContent="space-between"
      borderRadius="lg"
      border="1px"
      borderColor="brand.500"
    >
      <Box gap="2" display="flex" alignItems="start">
        <Box bg="brand.50" borderRadius="full" px="1" pt="1">
          <Icon color="brand.700" as={fileICon} w="4" h="4" />
        </Box>
        <Box>
          <Text color="gray.700" fontWeight="bold" noOfLines={1}>
            {fileName}
          </Text>
          <Text color="gray.500" fontSize="sm">
            {formatBytes(Number(size))}
          </Text>
        </Box>
      </Box>
      <IconButton
        onClick={onDelete}
        variant="ghost"
        aria-label="Delete File"
        icon={<FiTrash2 />}
      />
    </HStack>
  );
};

export default UploadedFile;

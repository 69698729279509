import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";

import useAudioRecording from "./hooks/useAudioRecorder";
import AudioPlayBack from "./RecordAudio/AudioPlayBack";
import AudioRecorder from "./RecordAudio/AudioRecorder";
import RecordingOptions from "./RecordingOptions";
import { newHarkState } from "./atoms/NewHarkStateAtom";
import VideoSnapShot from "./RecordVideo/VideoSnapshot";
import ScreenSnapShot from "./RecordScreen/ScreenSnapshot";
import { autoDetectedCompanyState } from "./atoms/autoDetactedCompanyAtom";
import { validationErrorState } from "./atoms/validationErrorsState";

const RecordHark = () => {
  const validationError = useRecoilValue(validationErrorState);
  const autoDetectedCompany = useRecoilValue(autoDetectedCompanyState);
  const { recording } = useRecoilValue(newHarkState);

  const {
    isRecordingAudio,
    audioDuration,
    audioVolumePercentage,
    startAudioRecording,
    stopAudioRecording,
  } = useAudioRecording();

  return (
    <Stack
      px={[5, 10]}
      py="8"
      gap="5"
      bg="white"
      rounded="md"
      maxWidth="700px"
      shadow="md"
    >
      {/* Heading */}
      <Box>
        <Heading size="sm">
          {autoDetectedCompany?.metaData.recordHarkTitle || "Record Hark"}
        </Heading>
        <Text>
          {autoDetectedCompany?.metaData.recordHarkMessage ||
            "Click Icons to Record Hark"}
        </Text>
      </Box>

      {/* IconButton */}
      {isRecordingAudio ? (
        <AudioRecorder
          audioDuration={audioDuration}
          audioVolumePercentage={audioVolumePercentage}
          stopAudioRecording={stopAudioRecording}
        />
      ) : (
        <RecordingOptions startAudioRecording={startAudioRecording} />
      )}

      {!!validationError.recording && (
        <Text color="red.400" fontSize="sm">
          {validationError.recording}
        </Text>
      )}

      {/* Recording Playback */}

      <Box minH={0} bg="brand.25">
        {recording && (
          <Box p={[2, 5]}>
            {recording.type === "audio" && <AudioPlayBack />}

            {recording.type === "video" && <VideoSnapShot />}

            {recording.type === "screen" && <ScreenSnapShot />}
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default RecordHark;

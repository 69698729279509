import { useRef, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import { recordingCompleteState } from "../atoms/RecordingStateAtom";

const constraints = {
  video: { facingMode: "environment" },
  audio: true,
};

export const useVideoRecorder = () => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const setRecordingComplete = useSetRecoilState(recordingCompleteState);
  const [duration, setDuration] = useState<number>(0);
  const [recordedDuration, setRecordedDuration] = useState<number>(0);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [loadingCams, setLoadingCams] = useState<boolean>(false);

  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);

  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
  const [cameraFacingMode, setCameraFacingMode] = useState("environment");

  const [recorder, setRecorder] = useState<MediaRecorder | null>();
  const [countDown, setCountDown] = useState(3);

  const timer: any = useRef(null);
  const startTimer = () =>
    (timer.current = setInterval(() => {
      setDuration((prev) => (prev += 1));
    }, 1000));

  const countDownTimer: any = useRef(null);
  const startCountDownTimer = () =>
    (timer.current = setInterval(() => {
      setCountDown((prev) => (prev += 1));
      if (countDown === 0) {
        stopCountDownTimer();
      }
    }, 1000));

  const stopCountDownTimer = () => {
    startRecording();

    clearInterval(countDownTimer.current);
  };

  const stopTimer = () => {
    const currentDuration = duration;
    console.log(currentDuration, duration);
    setRecordedDuration(currentDuration);
    clearInterval(timer.current);
    // setDuration(0);
  };

  const enumerateDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setCameras(videoDevices);
    } catch (err) {
      console.log("Error Enumerating device", err);
    }
  };

  const requestAccessToMediaDevices = async () => {
    setRecordingComplete(false);
    try {
      setLoadingCams(true);
      const mediaStream: MediaStream =
        await navigator.mediaDevices.getUserMedia(constraints);
      setLoadingCams(false);

      setVideoStream(mediaStream);
      enumerateDevices();
      let video = document.getElementById("record-video") as HTMLVideoElement;
      video.srcObject = mediaStream;
    } catch (err) {
      setLoadingCams(false);

      // TODO: better Error handling
    }
  };

  const reverseCameraFacing = async () => {
    const facingMode =
      cameraFacingMode === "environment" ? "user" : "environment";
    setCameraFacingMode(facingMode);

    const oldStream = videoStream;
    oldStream?.getTracks().forEach((t) => t.stop());

    const mediaStream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode },
      audio: true,
    });

    setVideoStream(mediaStream);

    let video = document.getElementById("record-video") as HTMLVideoElement;
    video.srcObject = mediaStream;
  };

  const changeCamera = async (deviceId: string) => {
    const camera = cameras.find((camera) => camera.deviceId === deviceId);
    if (!camera) return;
    const oldStream = videoStream;
    oldStream?.getTracks().forEach((t) => t.stop());

    const mediaStream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: camera.deviceId },
      audio: true,
    });
    setVideoStream(mediaStream);
    let video = document.getElementById("record-video") as HTMLVideoElement;
    video.srcObject = mediaStream;
  };

  const stopRecording = async () => {
    recorder?.stop();
    setIsRecording(false);
  };

  const closeCamera = () => {
    videoStream?.getTracks().forEach((t) => t.stop());
    setVideoStream(null);
  };

  const startRecording = async () => {
    if (!videoStream) return;

    const recorder = new MediaRecorder(videoStream);
    setRecorder(recorder);

    // where we are going to store recording
    const chunks: Blob[] = [];

    //   push recorded data to our chunks array
    recorder.ondataavailable = (event: BlobEvent) => {
      chunks.push(event.data);
      const videoBlob = new Blob(chunks, { type: "video/webm" });
      // Tear down after recording.
      closeCamera();
      setRecorder(null);
      setRecordingComplete(true);
      setNewHark({
        ...newHark,
        recording: {
          source: videoBlob,
          mediaType: "webm",
          type: "video",
        },
      });
    };

    recorder.onstop = () => {
      stopTimer();
      setIsRecording(false);
    };

    recorder.onstart = () => {
      setIsRecording(true);
      startTimer();
    };

    recorder.onerror = (e) => {
      // TODO: BETTER error handling
      console.log(e.error);
      stopRecording();
    };

    recorder.start();
  };

  return {
    videoStream,
    closeCamera,
    startCountDownTimer,
    requestAccessToMediaDevices,
    startRecording,
    stopRecording,
    isRecording,
    duration,
    cameras,
    changeCamera,
    reverseCameraFacing,
    loadingCams,
    recordedDuration,
  };
};

export default useVideoRecorder;

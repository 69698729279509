import axios from "axios";
import { IAuthResponse } from "../@types/IUser";

const BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  "https://second-caster-340917.uc.r.appspot.com/api/v1";

export async function googleLogin(idToken: string): Promise<IAuthResponse> {
  const response = await axios.post(`${BASE_URL}/auth/google`, {
    idToken,
  });
  return response.data;
}

export async function emailLogin(
  email: string,
  password: string
): Promise<IAuthResponse> {
  const response = await axios.post(`${BASE_URL}/auth/login/`, {
    email,
    password,
  });
  return response.data;
}

export async function signUp(
  email: string,
  password: string,
  name: string
): Promise<IAuthResponse> {
  const [firstName, lastName] = name.split(" ");
  const response = await axios.post(`${BASE_URL}/auth/register/`, {
    firstName,
    lastName,
    email,
    password,
  });
  return response.data;
}

export async function verifyEmail(token: string): Promise<IAuthResponse> {
  const response = await axios.get(
    `${BASE_URL}/auth/verify/?confirmation_token=${token}`
  );
  return response.data;
}

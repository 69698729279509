import { Image, Stack, Heading } from "@chakra-ui/react";

interface AutoSelectedCompanyProps {
  logoSrc: string;
  name: string;
  showBusinessName: boolean;
}

const AutoSelectedCompany = ({
  logoSrc,
  name,
  showBusinessName,
}: AutoSelectedCompanyProps) => {
  return (
    <Stack direction="row" width="full" align="center">
      <Image height={12} src={logoSrc} alt={`${name} logo`} />
      {showBusinessName && <Heading size="lg">{name}</Heading>}
    </Stack>
  );
};

export default AutoSelectedCompany;

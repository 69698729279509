import { Box } from "@chakra-ui/react";

interface CardProps {
  children?: React.ReactNode | React.ReactNode[];
  restProps?: any;
}
const Card = ({ children, restProps }: CardProps) => (
  <Box
    width="full"
    bg="white"
    rounded="xl"
    p={{ md: "5", base: "2" }}
    {...restProps}
  >
    {children}
  </Box>
);

export default Card;

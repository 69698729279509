import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Circle,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiCheck } from "react-icons/fi";
import { IoMdClose, IoMdReverseCamera } from "react-icons/io";
import { useRecoilState } from "recoil";
import { newHarkState } from "../atoms/NewHarkStateAtom";

const TakePhoto = ({
  isOpen,
  onOpen,
  onClose,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [videoStream, setVideoStream] = useState<MediaStream>();
  const [image, setImage] = useState<Blob>();
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
  const [picturePreviewUrl, setPicturePreveiwUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    isOpen &&
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: false,
        })
        .then((stream) => {
          setVideoStream(stream);
          enumerateDevices();
        });
  }, [isOpen]);

  const enumerateDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setCameras(videoDevices);
    } catch (err) {
      console.log("Error Enumerating device", err);
    }
  };

  const changeCamera = async (deviceId: string) => {
    const camera = cameras.find((camera) => camera.deviceId === deviceId);
    if (!camera) return;
    const oldStream = videoStream;
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: camera.deviceId },
      audio: false,
    });
    setVideoStream(mediaStream);
    oldStream?.getTracks().forEach((t) => t.stop());
  };

  const discardPhoto = () => {
    setImage(undefined);
    URL.revokeObjectURL(picturePreviewUrl);
  };

  const savePhoto = () => {
    const attachments = [...newHark.attachments];
    image &&
      attachments.push(
        new File([image], `photo${attachments.length}.png`, {
          type: image.type,
          lastModified: new Date().getTime(),
        })
      );
    setNewHark({ ...newHark, attachments });
    setImage(undefined);
    URL.revokeObjectURL(picturePreviewUrl);
    closeCamera();
    onClose();
  };

  const closeCamera = () => {
    videoStream?.getTracks().forEach((t) => t.stop());
    setVideoStream(undefined);
  };

  function takePhoto() {
    const videoPlayer = document.getElementById(
      "photo-video"
    ) as HTMLVideoElement;

    const canvas = document.createElement("canvas");
    canvas.width = videoPlayer?.videoWidth;
    canvas.height = videoPlayer?.videoHeight;

    // @ts-ignore TODO: HANDLE  IT
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");

    ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
    // return the canvas image as a blob
    ctx.canvas.toBlob(
      (blob) => {
        blob && setImage(blob);
        blob && setPicturePreveiwUrl(window.URL.createObjectURL(blob));
      },
      "image/jpeg",
      0.75 /* quality */
    );
  }
  return (
    // @ts-ignore

    <Modal size="xl" isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Box position="relative">
            {image ? (
              <Image src={picturePreviewUrl} />
            ) : (
              <video
                id="photo-video"
                playsInline
                ref={(video) => {
                  if (video && videoStream) {
                    video.srcObject = videoStream;
                  }
                }}
                muted
                autoPlay
              ></video>
            )}
            <HStack justifyContent="center">
              {image ? (
                <ButtonGroup
                  color="white"
                  position="absolute"
                  gap={5}
                  bottom={5}
                >
                  <IconButton
                    onClick={discardPhoto}
                    colorScheme="gray"
                    aria-label="Discard"
                    rounded="full"
                    icon={<IoMdClose />}
                    background="rgba(255, 255, 255, 0.25)"
                    color="black"
                  />
                  <IconButton
                    onClick={savePhoto}
                    colorScheme="gray"
                    aria-label="Save"
                    rounded="full"
                    icon={<FiCheck />}
                    background="rgba(255, 255, 255, 0.25)"
                    color="black"
                  />
                </ButtonGroup>
              ) : (
                <ButtonGroup
                  color="white"
                  position="absolute"
                  gap={5}
                  bottom={5}
                  alignItems="center"
                >
                  <IconButton
                    onClick={() => {
                      closeCamera();
                      onClose();
                    }}
                    colorScheme="gray"
                    aria-label="Discard"
                    rounded="full"
                    icon={<IoMdClose />}
                    background="rgba(255, 255, 255, 0.25)"
                    color="black"
                    size="sm"
                  />
                  <Button variant="unstyled" onClick={takePhoto}>
                    <Circle
                      size="12"
                      border="2px solid black"
                      outline="2px solid white"
                      background="rgba(255, 255, 255, 0.5)"
                    />
                  </Button>

                  <IconButton
                    colorScheme="gray"
                    aria-label="Discard"
                    rounded="full"
                    icon={<IoMdReverseCamera />}
                    background="rgba(255, 255, 255, 0.25)"
                    color="black"
                    size="sm"
                  />
                </ButtonGroup>
              )}
            </HStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TakePhoto;

import { atom } from "recoil";
import { ICompanyDetails } from "../../../@types/ICompany";

export const defaultAutoDetectedCompany: ICompanyDetails = {
  name: "Hark",
  settings: {
    showBusinessName: true,
    harkOptions: { video: true, audio: true, attachment: true, screen: true },
  },
  metaData: {
    recordHarkTitle: "Record Hark",
    recordHarkMessage:
      "Send us a video or audio recording of what you need help with",
    uploadTitle: "Upload additional documents",
    uploadMessage:
      "Kindly upload any other document or file that will be help in resolving the Hark",
  },
  embedOption: "standard",
} as ICompanyDetails;

export const autoDetectedCompanyState = atom({
  key: "autoDetectedCompanyState",
  default: null as ICompanyDetails | null,
});

import Input from "../../components/CustomInput";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { IContactDetails } from "../../@types/IContactDetails";
import { useRecoilState, useRecoilValue } from "recoil";
import { newHarkState } from "./atoms/NewHarkStateAtom";
import { validationErrorState } from "./atoms/validationErrorsState";
import { contactDetailsSchema, validateItem } from "./utils/newHarkValidator";
import LocationSearchInput from "./Address";
import { autoDetectedCompanyState } from "./atoms/autoDetactedCompanyAtom";

const ContactDetails = () => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [errors, setErrors] = useRecoilState(validationErrorState);
  const autoDetectedCompany = useRecoilValue(autoDetectedCompanyState);
  const collectAddress = autoDetectedCompany?.settings?.collectAddress;
  const hidePreferredContactMethod =
    autoDetectedCompany?.settings?.hidePreferredContactMethod;

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = target;
    if (name === "phone") value = value.replaceAll(" ", "");

    const contactDetails = {
      ...newHark.contactDetails,
      [name]: value,
    } as IContactDetails;

    setNewHark({ ...newHark, contactDetails });

    const error = validateItem(contactDetailsSchema[name], value);

    setErrors({ ...errors, [name]: error });
  };

  return (
    <Stack
      px={[5, 10]}
      py="8"
      bg="white"
      borderRadius="md"
      maxWidth="700px"
      shadow="md"
    >
      <Heading color="brand.800" size="sm">
        Contact Details
      </Heading>
      <Text color="GrayText">
        Kindly provide your contact information to enable us communicate with
        you
      </Text>
      <Stack gap={2}>
        <Grid templateColumns="repeat(2, 1fr)" gap="4">
          <FormControl isInvalid={!!errors.firstName}>
            <Input
              value={newHark.contactDetails.firstName}
              name="firstName"
              onChange={handleChange}
              label="First Name"
              type="text"
            />
            <FormErrorMessage>{errors.firstName}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.lastName}>
            <Input
              value={newHark.contactDetails.lastName}
              name="lastName"
              onChange={handleChange}
              isRequired
              label="Last Name"
              type="text"
            />
            <FormErrorMessage>{errors.lastName}</FormErrorMessage>
          </FormControl>
        </Grid>

        <FormControl isInvalid={!!errors.email}>
          <Input
            value={newHark.contactDetails.email}
            name="email"
            onChange={handleChange}
            label="Contact Email"
            type="email"
          />
          <FormErrorMessage>{errors.email}</FormErrorMessage>
        </FormControl>

        {newHark.contactDetails.communicationMethod === "phone" && (
          <FormControl isInvalid={!!errors.phone}>
            <Input
              value={newHark.contactDetails.phone}
              name="phone"
              onChange={handleChange}
              label="Phone Number"
              type="tel"
              pattern="[0-9]{4,}$"
              title="Phone number should be at least 4 numbers"
            />
            <FormErrorMessage>{errors.phone}</FormErrorMessage>
          </FormControl>
        )}

        {!hidePreferredContactMethod && (
          <FormControl isInvalid={!!errors.communicationMethod}>
            <FormLabel fontSize="sm" color="GrayText">
              Preferred Communication Method:
            </FormLabel>
            <RadioGroup
              size="sm"
              name="communication_method"
              onChange={(e: "email" | "phone") => {
                setNewHark({
                  ...newHark,
                  contactDetails: {
                    ...newHark.contactDetails,
                    communicationMethod: e,
                  },
                });
              }}
              value={newHark.contactDetails.communicationMethod}
            >
              <Stack direction="row">
                <Radio value="email">Email</Radio>
                <Radio value="phone">Phone</Radio>
              </Stack>
            </RadioGroup>
            <FormErrorMessage>{errors.communicationMethod}</FormErrorMessage>
          </FormControl>
        )}

        {!!collectAddress && (
          <FormControl>
            <LocationSearchInput isRequired={collectAddress === "required"} />
            <FormErrorMessage>{errors.address}</FormErrorMessage>
          </FormControl>
        )}
      </Stack>
    </Stack>
  );
};

export default ContactDetails;

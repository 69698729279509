import { useState } from "react";
import { useRecoilState } from "recoil";

import { newHarkState } from "../atoms/NewHarkStateAtom";

export const useAudioPlayback = () => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [duration] = useState<number>(0);
  const [isPlayingAudio, setIsPlayingAudio] = useState<boolean>(false);

  const audioPlayer = new Audio(newHark.recording?.playbackUrl);

  const deleteRecording = () => {
    audioPlayer.pause();
    audioPlayer.currentTime = 0;
    newHark.recording?.playbackUrl &&
      URL.revokeObjectURL(newHark.recording?.playbackUrl);

    // store blob in newHark state for submission
    setNewHark({
      ...newHark,
      recording: null,
    });
  };

  const playAudio = async () => {
    audioPlayer?.addEventListener("ended", function () {
      setIsPlayingAudio(false);
    });

    if (isPlayingAudio) {
      setIsPlayingAudio(false);
      audioPlayer.pause();
    } else {
      setIsPlayingAudio(true);
      audioPlayer?.play();
    }
  };

  return {
    playAudio,
    audioDuration: duration,
    deleteRecording,
    isPlayingAudio,
  };
};

export default useAudioPlayback;

import { FiMapPin } from "react-icons/fi";
import PlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-places-autocomplete";
import {
  CircularProgress,
  FormControl,
  Button,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";

import CustomInput from "../../components/CustomInput";
import { useRecoilState } from "recoil";
import { newHarkState } from "./atoms/NewHarkStateAtom";
import { IContactDetails } from "../../@types/IContactDetails";
import { contactDetailsSchema, validateItem } from "./utils/newHarkValidator";
import { validationErrorState } from "./atoms/validationErrorsState";

interface LocationSearchInputProps {
  isRequired: boolean;
}

const LocationSearchInput = ({ isRequired }: LocationSearchInputProps) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const { contactDetails } = newHark;
  const [errors, setErrors] = useRecoilState(validationErrorState);

  const handleChange = (value: string) => {
    const contactDetails = {
      ...newHark.contactDetails,
      address: value,
    } as IContactDetails;
    setNewHark({ ...newHark, contactDetails });
    const error = validateItem(contactDetailsSchema["address"], value);

    setErrors({ ...errors, address: error });
  };

  return (
    <PlacesAutocomplete
      value={contactDetails.address}
      onChange={handleChange}
      onSelect={(address) => {
        console.log(address);
      }}
    >
      {({ getInputProps, suggestions, loading }) => (
        <div>
          <FormControl label="Address" isRequired={isRequired}>
            <CustomInput
              {...getInputProps({
                className: "location-search-input",
                label: "Address",
                type: "text",
              })}
            />
          </FormControl>
          <Stack>
            {loading && (
              <HStack width="full">
                <CircularProgress size="15px" isIndeterminate />
                <Text>Loading...</Text>
              </HStack>
            )}
            {suggestions.map((suggestion) => {
              return (
                <Button
                  width="full"
                  size="md"
                  variant="ghost"
                  colorScheme="brand"
                  justifyContent="start"
                  fontWeight="normal"
                  isActive={suggestion.active}
                  leftIcon={<FiMapPin />}
                  _active={{ bg: "brand.300", color: "white" }}
                  whiteSpace="normal"
                  onClick={() => {
                    handleChange(suggestion.description);
                  }}
                  onMouseOver={() => {
                    handleChange(suggestion.description);
                  }}
                >
                  {suggestion.description}
                </Button>
              );
            })}
          </Stack>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;

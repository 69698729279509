import { useRef, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { newHarkState } from "../atoms/NewHarkStateAtom";
import { recordingCompleteState } from "../atoms/RecordingStateAtom";

import audioThumbnail from "../../../assets/images/audio_thunail.png";

export const useAudioRecorder = () => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const setRecordingComplete = useSetRecoilState(recordingCompleteState);
  const [duration, setDuration] = useState<number>(0);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordingDevices, setRecordingDevices] = useState<MediaDeviceInfo[]>(
    []
  );
  const [audioDeviceId, setAudioDeviceId] = useState<string>("");
  const [recorder, setRecorder] = useState<MediaRecorder | null>();
  const [audioSource, setAudioSource] = useState<HTMLAudioElement | null>();
  const timer: any = useRef(null);

  const [volumePercent, setVolumePercent] = useState(0);

  const startTimer = () =>
    (timer.current = setInterval(() => {
      setDuration((prev) => (prev += 1));
    }, 1000));

  const stopTimer = () => clearInterval(timer.current);

  const getDevices = async () => {
    const devices: MediaDeviceInfo[] =
      await navigator.mediaDevices.enumerateDevices();
    const audioDevices = devices.filter(
      ({ kind, deviceId }) => kind === "audioinput" && Boolean(deviceId)
    );
    setRecordingDevices(audioDevices);
  };

  const startRecording = async () => {
    setAudioSource(null);
    const audio = audioDeviceId ? { deviceId: { exact: audioDeviceId } } : true;

    const mediaStream = await navigator.mediaDevices.getUserMedia({ audio });

    const recorder = new MediaRecorder(mediaStream);
    setRecorder(recorder);

    // where we are going to store recording
    const chunks: Blob[] = [];

    // Create an AudioContext
    const audioContext = new AudioContext();

    // Create the Analyser
    const analyser = audioContext.createAnalyser();

    // Connect a media stream source to connect to the analyser
    const source = audioContext.createMediaStreamSource(recorder.stream);

    // Create a Uint8Array based on the frequencyBinCount(fftSize / 2)
    const dataArray: any = new Uint8Array(analyser.frequencyBinCount);

    // REPORT is a function run on each animation frame until recording === false
    const report = () => {
      // Copy the frequency data into DATA_ARR
      analyser.getByteFrequencyData(dataArray);

      const volume = Math.floor((Math.max(...dataArray) / 255) * 100);
      setVolumePercent(volume);
      // If we are still recording, run REPORT again in the next available frame
      requestAnimationFrame(report);
    };

    // Connect the analyser
    source.connect(analyser);

    //   push recorded data to our chunks array
    recorder.ondataavailable = async (event: BlobEvent) => {
      chunks.push(event.data);
      const audioBlob = new Blob(chunks, { type: "audio/webm" });

      setRecordingComplete(true);
      // Tear down after recording.
      recorder.stream.getTracks().forEach((t) => t.stop());
      setRecorder(null);

      const res = await fetch(audioThumbnail);
      let thumbNail = await res.blob();

      // store blob in newHark state for submission
      setNewHark({
        ...newHark,
        recording: {
          source: audioBlob,
          mediaType: "webm",
          type: "audio",
          playbackUrl: window.URL.createObjectURL(audioBlob),
        },
        thumbNail,
      });
    };

    recorder.onstop = () => {
      stopTimer();
      analyser.disconnect();
      source.disconnect();
      audioContext.close();
      setVolumePercent(0);
    };

    recorder.onstart = () => {
      setIsRecording(true);
      startTimer();
      report();
    };

    recorder.onerror = (e) => {
      // TODO: BETTER error handling

      console.log(e.error);
    };

    recorder.start();
  };

  const stopRecording = async () => {
    recorder?.stop();
    setIsRecording(false);
  };

  return {
    isRecordingAudio: isRecording,
    audioSource,
    audioDuration: duration,
    startAudioRecording: startRecording,
    stopAudioRecording: stopRecording,
    audioVolumePercentage: volumePercent,
  };
};

export default useAudioRecorder;

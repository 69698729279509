import { useNavigate } from "react-router-dom";
import {
  Button,
  Heading,
  Progress,
  Stack,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { ImWarning } from "react-icons/im";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { defaultHarkState, newHarkState } from "./atoms/NewHarkStateAtom";
import { useState } from "react";
import { createHark } from "../../api/hark";
import { autoDetectedCompanyState } from "./atoms/autoDetactedCompanyAtom";
import HarkSentIcon from "./HarkSentIcon";
import { validateNewHark } from "./utils/newHarkValidator";
import { validationErrorState } from "./atoms/validationErrorsState";
import { loggedInUserState } from "../../atoms/UserAthomState";
import { recordingCompleteState } from "./atoms/RecordingStateAtom";

export const SendHark = () => {
  const navigate = useNavigate();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const autoDetectedCompany = useRecoilValue(autoDetectedCompanyState);
  const [errorMessage, setErrorMessage] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const resetRecordingCompleteState = useResetRecoilState(
    recordingCompleteState
  );
  const setValidationErrors = useSetRecoilState(validationErrorState);

  const validateHark = () => {
    let hark = newHark;
    if (loggedInUser) hark = { ...newHark };
    console.log(newHark);
    const errors = validateNewHark(hark);
    console.log(errors);
    if (!errors) submitHark();
    else setValidationErrors(errors);
  };

  const submitHark = async () => {
    onOpen();
    try {
      setLoading(true);
      setErrorMessage("");
      await createHark(newHark);
      newHark.recording?.playbackUrl &&
        URL.revokeObjectURL(newHark.recording?.playbackUrl);
      resetRecordingCompleteState();
      setNewHark(defaultHarkState);
      setLoading(false);
    } catch (err: any) {
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Error Sending Hark, Try again";
      setErrorMessage(errorMessage);
      setLoading(false);
    }
  };

  const onclose = () => {
    onClose();
    window.parent && window.parent.postMessage("hark sent", "*");

    window.location.reload();
  };

  const goHome = () => {
    onClose();
    navigate("/");
  };

  const hark = autoDetectedCompany ? "Message" : "Hark";
  return (
    <>
      {/* TODO: is recordingcomplete atom really needed? */}
      {newHark.recording && (
        <Button
          onClick={validateHark}
          size="lg"
          colorScheme="brand"
          color="brand.buttonText"
          variant="solid"
        >
          Submit {hark}
        </Button>
      )}
      <Modal
        closeOnOverlayClick={loading ? false : true}
        size="xs"
        isOpen={isOpen}
        onClose={onclose}
      >
        <ModalOverlay />
        <ModalContent>
          {loading && <ModalHeader>Sending {hark}</ModalHeader>}
          <ModalCloseButton onClick={onclose} />
          <ModalBody>
            {loading && (
              <Progress colorScheme="brand" size="lg" isIndeterminate />
            )}
            {!loading && !errorMessage && (
              <Stack mt="5">
                <HarkSentIcon />
                <Heading size="lg">{hark} Sent!</Heading>
                <Text color="GrayText">
                  Your submission is being reviewed! Please expect a response
                  within 24 hours.
                </Text>
              </Stack>
            )}
            {!loading && errorMessage && (
              <Stack>
                <HStack color="red.400">
                  <Icon as={ImWarning} w="5" h="5" />{" "}
                  <Text fontSize="lg" fontWeight="bold">
                    {hark} not sent
                  </Text>
                </HStack>
                <Text color="GrayText">{errorMessage}</Text>
              </Stack>
            )}
          </ModalBody>
          <ModalFooter>
            {/* TODO: use custom button  */}
            {!loading && !errorMessage && (
              <Button
                width="full"
                bg="brand.50"
                color="brand.700"
                onClick={autoDetectedCompany ? onclose : goHome}
                _hover={{ bg: "brand.100" }}
              >
                {autoDetectedCompany ? "Done" : "Go to my Harks"}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default SendHark;

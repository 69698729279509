// Library Imports
import { useRecoilState, useRecoilValue } from "recoil";

// Local Imports
import { Heading, Stack, Text } from "@chakra-ui/react";
import { ICompany } from "../../../@types/ICompany";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import CompanyOption from "./CompanyOption";
import CustomSelect, {
  ICustomSelectOption,
} from "../../../components/CustomSelect";
import AutoSelectedCompany from "./AutoSelectedCompany";
import { autoDetectedCompanyState } from "../atoms/autoDetactedCompanyAtom";
import { validationErrorState } from "../atoms/validationErrorsState";
import { newHarkObjectSchema, validateItem } from "../utils/newHarkValidator";

interface SelectCompanyProps {
  loading: boolean;
  companies: ICompany[];
}

const SelectMerchant = ({ loading, companies }: SelectCompanyProps) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const autoDetectedCompany = useRecoilValue(autoDetectedCompanyState);
  const [validationErrors, setValidationErrors] =
    useRecoilState(validationErrorState);

  // When the user selects  a company
  const handleChange = (option: ICustomSelectOption | null) => {
    setNewHark({ ...newHark, company: option?.value ? option.value : "" });
    const error = validateItem(newHarkObjectSchema.company, option?.value);
    setValidationErrors({ ...validationErrors, company: error });
  };

  const options = companies.map((company: ICompany) => ({
    element: <CompanyOption name={company.name} logo={company.logo_url} />,
    value: company._id,
  }));

  return autoDetectedCompany ? (
    <>
      <Stack pt={5}>
        <AutoSelectedCompany
          name={autoDetectedCompany?.name}
          logoSrc={autoDetectedCompany?.logo_url}
          showBusinessName={autoDetectedCompany?.settings?.showBusinessName}
        />
      </Stack>
    </>
  ) : (
    <Stack
      px={["4", "10"]}
      py="8"
      bg="white"
      rounded="md"
      maxWidth="700px"
      shadow="md"
    >
      <Heading size="sm">Merchant</Heading>
      <Text>Hark will be sent to: </Text>

      <CustomSelect
        value={
          options.find((option) => newHark.company === option.value) || null
        }
        isInvalid={!!validationErrors.company}
        loading={loading}
        onChange={handleChange}
        label="Merchant"
        options={options}
      />
      <Text fontSize="xs" color="red.500">
        {validationErrors.company}
      </Text>
    </Stack>
  );
};

export default SelectMerchant;

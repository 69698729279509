import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  HStack,
  Spinner,
  Center,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Select,
} from "@chakra-ui/react";
import { FiSettings } from "react-icons/fi";
import { secondsToHH_MM_SS } from "../../../utils/dateFormatter";

interface DesktopRecordModalProps {
  loading: boolean;
  isOpen: boolean;
  onClose: () => void;
  videoStream: MediaStream | null;
  startRecording: () => Promise<void>;
  isRecording: boolean;
  changeCamera: (deviceId: string) => void;
  cameras: MediaDeviceInfo[];
  handleStopRecording: () => void;
  duration: number;
}

export const DesktopRecordModal = ({
  loading,
  isOpen,
  onClose,
  videoStream,
  startRecording,
  isRecording,
  changeCamera,
  cameras,
  handleStopRecording,
  duration,
}: DesktopRecordModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">
          <HStack justify="space-between">
            <Text> Record Video Clip</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton size="sm" isDisabled={loading} />
        <ModalBody>
          {loading && (
            <Center paddingY={20}>
              <Spinner
                thickness="5px"
                speed="1s"
                emptyColor="gray.200"
                color="brand.100"
                size="xl"
              />
            </Center>
          )}
          <Box width="full" position="relative">
            <video
              style={{ width: "100%" }}
              id={"record-video"}
              playsInline
              muted
              autoPlay
            ></video>
            <Popover placement="right-start">
              <PopoverTrigger>
                <IconButton
                  position="absolute"
                  bottom={0}
                  left={0}
                  variant="ghost"
                  aria-label="Send email"
                  color="white"
                  rounded="full"
                  size="sm"
                  colorScheme="brand"
                  icon={<FiSettings size={20} />}
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody>
                  <Select
                    onChange={(e) => {
                      changeCamera(e.target.value);
                    }}
                    placeholder="Select Camera"
                  >
                    {cameras.map((camera) => (
                      <option value={camera.deviceId}>{camera.label}</option>
                    ))}
                  </Select>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </ModalBody>

        <ModalFooter>
          {isRecording ? (
            <HStack width="full" justify="space-between">
              <Text>{secondsToHH_MM_SS(duration)}</Text>
              <Box display="flex">
                <Button
                  variant="outline"
                  colorScheme="gray"
                  mr={3}
                  onClick={onClose}
                  isDisabled={!videoStream}
                >
                  Pause
                </Button>
                {/* TODO:  make colorscheme or custom button for this button with stye*/}
                <Button
                  isDisabled={!videoStream}
                  _hover={{ bg: "brand.100" }}
                  bg="brand.50"
                  variant="solid"
                  onClick={handleStopRecording}
                >
                  Stop
                </Button>
              </Box>
            </HStack>
          ) : (
            <>
              <Button
                variant="outline"
                colorScheme="gray"
                mr={3}
                onClick={onClose}
                isDisabled={loading}
              >
                Cancel
              </Button>
              {/* TODO:  make colorscheme or custom button for this button with stye*/}
              <Button
                isDisabled={!videoStream}
                _hover={{ bg: "brand.100" }}
                bg="brand.50"
                variant="solid"
                onClick={startRecording}
              >
                Record
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

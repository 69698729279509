import {
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { IoMdPlay, IoMdPause, IoMdClose } from "react-icons/io";
import { GiSoundWaves } from "react-icons/gi";

import { IconButton } from "../TooltipButton";

import { secondsToHH_MM_SS } from "../../../utils/dateFormatter";
import { useRecoilState } from "recoil";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import useAudioPlayback from "../hooks/useAudioPlayBack";
import { validationErrorState } from "../atoms/validationErrorsState";
import { newHarkObjectSchema, validateItem } from "../utils/newHarkValidator";

const AudioPlayBack = () => {
  const { playAudio, audioDuration, isPlayingAudio, deleteRecording } =
    useAudioPlayback();
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [validationErrors, setValidationErrors] =
    useRecoilState(validationErrorState);

  return (
    <Stack bg="white" px={[3, 5]} pb="0" py="5" position="relative" shadow="md">
      <IconButton
        onClick={deleteRecording}
        size="xs"
        bg="white"
        boxShadow="lg"
        p="1"
        as={IoMdClose}
        position="absolute"
        top="-2"
        right="-2"
      />

      <HStack>
        <IconButton
          onClick={playAudio}
          color="brand.700"
          bg="white"
          border="1px"
          borderColor="brand.700"
        >
          {isPlayingAudio ? <IoMdPause /> : <IoMdPlay />}
        </IconButton>
        <HStack height={16} gap={0} overflow="hidden">
          {new Array(13).fill(0).map((_, index) => (
            <Icon
              key={index}
              w={isPlayingAudio ? Math.ceil(Math.random() * 20) : 16}
              h={isPlayingAudio ? Math.ceil(Math.random() * 20) : 16}
              as={GiSoundWaves}
              color={isPlayingAudio ? "brand.200" : "gray.200"}
            />
          ))}
        </HStack>
        <Text w="48" textAlign="right" fontSize="xs">
          {secondsToHH_MM_SS(audioDuration)}
        </Text>
      </HStack>
      <FormControl isInvalid={!!validationErrors.productName}>
        <Input
          value={newHark.productName}
          placeholder="Enter Product Name Here"
          onChange={(e) => {
            setNewHark({ ...newHark, productName: e.target.value });
            const error = validateItem(
              newHarkObjectSchema.productName,
              e.target.value
            );
            setValidationErrors({ ...validationErrors, productName: error });
          }}
        />
        <FormErrorMessage>{validationErrors.productName}</FormErrorMessage>
      </FormControl>
    </Stack>
  );
};

export default AudioPlayBack;

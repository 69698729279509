import { Box, Text, Image } from "@chakra-ui/react";

type CompanyOptionProps = {
  name: string;
  logo: string;
};
const CompanyOption = ({ name, logo }: CompanyOptionProps) => {
  return (
    <Box
      width="full"
      bg="brand.25"
      display="flex"
      alignItems="center"
      gap="2"
      p="3"
      borderRadius="sm"
    >
      <Image height="20px" alt="logo" src={logo} />
      <Text fontWeight="medium">{name}</Text>
    </Box>
  );
};

export default CompanyOption;

import {
  Box,
  Center,
  CircularProgress,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { verifyEmail } from "../../api/auth";
import { loggedInUserState } from "../../atoms/UserAthomState";

const VerifyEmailPage = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { search, state } = useLocation();
  const token = new URLSearchParams(search).get("confirmation_token");
  const setUser = useSetRecoilState(loggedInUserState);
  const [error, setError] = useState<string | null>(null);
  // @ts-ignore

  const from = state?.from || "/";

  useEffect(() => {
    setError(null);
    setLoading(true);
    if (!token) {
      setError("Verification Failed: Invalid Token");
      setLoading(false);

      return;
    }

    verifyEmail(token)
      .then(({ payload }: any) => {
        setLoading(false);
        const user = {
          email: payload.email,
          firstNme: payload.first_name,
          lastName: payload.last_name,
          photo: payload.photo_url,
        };
        setUser(user);
        toast({
          title: "Verification Successful.",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
          variant: "subtle",
        });
        localStorage.setItem("hark-token", payload.token);
        // Todo! Get User form API
        localStorage.setItem("hark-user", JSON.stringify(user));
        // Redirect the user to where he came from
        navigate(from, { replace: true });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError("Verification Failed: Invalid Token");
      });

    !token && setLoading(false);
  }, [search]);
  return (
    <Box width="full" height="100vh">
      <Center height="full">
        {loading && (
          <>
            <CircularProgress /> <Text>Verifying Email...</Text>
          </>
        )}

        {error && <Text color="red.600">{error}</Text>}
      </Center>
    </Box>
  );
};

export default VerifyEmailPage;

import { atom } from "recoil";
import { IContactDetails } from "../../../@types/IContactDetails";
import { INewHark } from "../../../@types/IHark";

export const defaultHarkState = {
  company: "",
  contactDetails: { communicationMethod: "email" } as IContactDetails,
  recording: null,
  attachments: [] as File[],
} as INewHark;

export const newHarkState = atom({
  key: "newHarkState",
  default: defaultHarkState,
});

import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RecoilRoot, useRecoilValue } from "recoil";
import SidebarWithHeader from "./components/layout/NavBar";
import HarksPage from "./pages/Dashbard";
import LoginPage from "./pages/LoginPage/Index";
import TrackingPage from "./pages/Tracking/";
import NewHarkPage from "./pages/NewHarkPage/Index";
import NotificationsPage from "./pages/Notifications";
import SettingsPage from "./pages/Settings";
import SupportPage from "./pages/Support";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import { brandColorsThemeState } from "./atoms/brandColorsThemeState";
import { colors, fonts, components } from "./config/defaultTheme";

export const App = () => (
  <RecoilRoot>
    <BrowserRouter>
      <ThemedApp />
    </BrowserRouter>
  </RecoilRoot>
);

function ThemedApp() {
  const brandColors = useRecoilValue(brandColorsThemeState);
  const theme = {
    colors: { ...colors, brand: brandColors },
    fonts,
    components,
  };
  return (
    <ChakraProvider theme={extendTheme(theme)}>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="verify" element={<VerifyEmailPage />} />

        <Route path="/" element={<SidebarWithHeader />}>
          <Route index element={<HarksPage />} />
          <Route path="notifications" element={<NotificationsPage />} />
          <Route path="new" element={<NewHarkPage />}>
            <Route path=":companyID" element={<NewHarkPage />} />
          </Route>
          <Route path="harks/:id" element={<TrackingPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="support" element={<SupportPage />} />
        </Route>
      </Routes>
    </ChakraProvider>
  );
}

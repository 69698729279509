import { useRef, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import { recordingCompleteState } from "../atoms/RecordingStateAtom";
const constraints = {
  video: true,
};

export const useScreenRecorder = () => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const setRecordingComplete = useSetRecoilState(recordingCompleteState);
  const [duration, setDuration] = useState<number>(0);
  const [isRecording, setIsRecording] = useState<boolean>(false);

  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);

  const [recorder, setRecorder] = useState<MediaRecorder | null>();
  const [countDown, setCountDown] = useState(3);

  const timer: any = useRef(null);
  const startTimer = () =>
    (timer.current = setInterval(() => {
      setDuration((prev) => (prev += 1));
    }, 1000));

  const countDownTimer: any = useRef(null);
  const startCountDownTimer = () =>
    (timer.current = setInterval(() => {
      setCountDown((prev) => (prev += 1));
      if (countDown === 0) {
        stopCountDownTimer();
      }
    }, 1000));

  const stopCountDownTimer = () => {
    startRecording();
    clearInterval(countDownTimer.current);
  };

  const stopTimer = () => clearInterval(timer.current);

  const requestAccessToMediaDevices = async () => {
    setRecordingComplete(false);
    try {
      // @ts-ignore
      const mediaStream = await navigator.mediaDevices.getDisplayMedia(
        constraints
      );
      setVideoStream(mediaStream);

      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setAudioStream(audioStream);
    } catch (err: any) {
      // TODO: better Error handling
      throw new Error(err);
    }
  };

  const stopRecording = async () => {
    recorder?.stop();
    setIsRecording(false);
  };

  const closeCamera = () => {
    videoStream?.getTracks().forEach((t) => t.stop());
    audioStream?.getTracks().forEach((t) => t.stop());

    setVideoStream(null);
    setAudioStream(null);
  };

  const startRecording = async () => {
    if (!videoStream || !audioStream) return;

    // @ts-ignore
    const mixedStream = new MediaStream([
      ...videoStream.getTracks(),
      ...audioStream.getTracks(),
    ]);

    const recorder = new MediaRecorder(mixedStream);
    setRecorder(recorder);

    // where we are going to store recording
    const chunks: Blob[] = [];

    //   push recorded data to our chunks array
    recorder.ondataavailable = (event: BlobEvent) => {
      chunks.push(event.data);
      const videoBlob = new Blob(chunks, { type: "video/mp4" });
      // Tear down after recording.
      closeCamera();
      setRecorder(null);
      setRecordingComplete(true);
      setNewHark({
        ...newHark,
        recording: {
          source: videoBlob,
          mediaType: "mp4",
          type: "video",
        },
      });
    };

    recorder.onstop = () => {
      stopTimer();
      setIsRecording(false);
    };

    recorder.onstart = () => {
      setIsRecording(true);
      startTimer();
    };

    recorder.onerror = (e) => {
      // TODO: BETTER error handling
      console.log(e.error);
    };

    recorder.start();
  };

  return {
    videoStream,
    closeCamera,
    startCountDownTimer,
    requestAccessToMediaDevices,
    startRecording,
    stopRecording,
    isRecording,
    duration,
  };
};

export default useScreenRecorder;

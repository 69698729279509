export function generateThumbnail(callback: (blob: Blob) => void) {
  const seekTo = 0.0;
  const videoPlayer = document.getElementsByTagName("video")[0];
  // extract video thumbnail once seeking is complete
  videoPlayer.addEventListener("seeked", () => {
    console.log("video is now paused at %ss.", seekTo);
    // define a canvas to have the same dimension as the video
    const canvas = document.createElement("canvas");
    canvas.width = videoPlayer.videoWidth;
    canvas.height = videoPlayer.videoHeight;
    // draw the video frame to canvas
    // @ts-ignore TODO: HANDLE  IT
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");

    ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
    // return the canvas image as a blob
    ctx.canvas.toBlob(
      (blob) => {
        blob && callback(blob);
      },
      "image/jpeg",
      0.75 /* quality */
    );
  });

  // delay seeking or else 'seeked' event won't fire on Safari
  setTimeout(() => {
    videoPlayer.currentTime = seekTo;
  }, 200);
}
export const d = "d";

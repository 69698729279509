// Library Imports
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

// Local Imports
import {
  Box,
  Container,
  Heading,
  HStack,
  Image,
  Skeleton,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import SelectCompany from "./SelectCompany/SelectCompany";
import ContactDetails from "./ContactDetails";
import RecordHark from "./RecordHark";
import SendHark from "./SubmitHark";
import HarkLogin from "./HarkLogin";
import { newHarkState } from "./atoms/NewHarkStateAtom";
import { loggedInUserState } from "../../atoms/UserAthomState";
import { useParams } from "react-router-dom";
import CompanyHeading from "./SelectCompany/CompanyHeading";
import {
  autoDetectedCompanyState,
  defaultAutoDetectedCompany,
} from "./atoms/autoDetactedCompanyAtom";
import { brandColorsThemeState } from "../../atoms/brandColorsThemeState";
import { getCompanies, getCompanyById } from "../../api/companies";
import { ICompany } from "../../@types/ICompany";
import harkLogo from "../../assets/svg/HarkLogoWide.svg";
import Attachment from "./Attachment/Attachment";
import { updateFavicon } from "../../utils/favicon";

export default function NewHarkPage() {
  const { companyID } = useParams();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const { recording, company } = newHark;
  const [showContactForm, setShowContactForm] = useState(false);
  const [showAttachment, setShowAttachment] = useState(false);

  const toast = useToast();
  const [brandColours, setBrandColors] = useRecoilState(brandColorsThemeState);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [loading, setLoading] = useState(false);
  const [autoDetectedCompany, setAutoDetectedCompany] = useRecoilState(
    autoDetectedCompanyState
  );

  const fetchCompanies = async () => {
    try {
      setLoading(true);

      // if company id is provided try to fetch company
      if (companyID) {
        getCompanyById(companyID)
          .then(({ company }) => {
            updateFavicon(company.logo_url);
            document.title = company.name;
            setNewHark({
              ...newHark,
              company: company?._id!,
            });

            setAutoDetectedCompany({
              ...defaultAutoDetectedCompany,
              ...company,
            });

            company.brandColours &&
              setBrandColors({ ...brandColours, ...company.brandColours });

            setLoading(false);
            return;
          })
          .catch((error) => {
            setLoading(false);

            toast({
              status: "warning",
              title: "Invalid client id",
              description: "Default to company selection",
              variant: "subtle",
              position: "top-right",
            });
          });
      }

      const data = await getCompanies();

      setLoading(false);
      setCompanies(data.companies);
    } catch (err) {
      // TODO: better error handling
      setLoading(false);

      console.log(err);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    // when Recording completes
    if (recording) {
      setShowAttachment(true);
      setShowContactForm(true);

      // if once the user is logged in and recording is complete the contact form is auto filled
      if (loggedInUser) {
        setNewHark({
          ...newHark,
          contactDetails: {
            email: loggedInUser.email,
            firstName: loggedInUser.firstNme,
            lastName: loggedInUser.lastName,
            communicationMethod: "email",
            phone: "",
          },
        });

        // if the company is auto detected, no need to show harkLogin
      } else if (companyID && companyID === company) {
        setShowContactForm(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser, recording]);

  return (
    <Box width="full" minH="100vh" bg="brand.25">
      <Container px={2} position="relative">
        {loading ? (
          <Stack gap={5} pt={5}>
            <Skeleton height={12} width={24} rounded="md" />
            <Skeleton height={200} width="full" rounded="md" />
            <Skeleton height={14} width="full" rounded="md" />
          </Stack>
        ) : (
          <Stack gap="5">
            {/* Heading */}
            {!autoDetectedCompany && (
              <Heading mt={5} fontWeight="semibold" size="lg">
                Create a new hark
              </Heading>
            )}

            <SelectCompany companies={companies} loading={loading} />
            {autoDetectedCompany && (
              <CompanyHeading {...autoDetectedCompany.metaData} />
            )}

            <RecordHark />

            {(showAttachment || newHark.attachments.length) && <Attachment />}

            {newHark.recording && !loggedInUser && !companyID && <HarkLogin />}

            {showContactForm && <ContactDetails />}

            <SendHark />
          </Stack>
        )}

        <HStack mt={5} width="full" justifyContent="end">
          <Text fontSize="xs">Powered by</Text>
          <Image h="12px" src={harkLogo} />
        </HStack>
      </Container>
    </Box>
  );
}

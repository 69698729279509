import {
  Button,
  HStack,
  Stack,
  Text,
  Circle,
  ButtonGroup,
} from "@chakra-ui/react";
import { FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { newHarkState } from "./atoms/NewHarkStateAtom";

const HarkLogin = () => {
  return (
    <Stack>
      <HStack
        px={[5, 10]}
        py={[3, 5]}
        width="full"
        bg="white"
        rounded="md"
        justify="space-between"
      >
        <Circle size="10" bg="primary.50" color="gray.600">
          <FiUser size="24" />
        </Circle>

        <Text fontSize={["xs", "md"]}>Hark Login</Text>

        <ButtonGroup>
          <Button
            size={["xs", "sm"]}
            variant="ghost"
            color="gray.500"
            state={{ from: "/new" }}
            to="/login"
            as={Link}
          >
            Login
          </Button>
          <Button
            state={{ from: "/new" }}
            to="/signup"
            as={Link}
            size={["xs", "sm"]}
            variant="ghost"
            colorScheme="primary"
          >
            Sign up
          </Button>
        </ButtonGroup>
      </HStack>
    </Stack>
  );
};
export default HarkLogin;

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Box,
  Text,
  HStack,
  Input,
  VStack,
} from "@chakra-ui/react";
import { useRef } from "react";
import { IoMdRefresh } from "react-icons/io";
import { useRecoilState } from "recoil";

import { secondsToHH_MM_SS } from "../../../utils/dateFormatter";
import { generateThumbnail } from "../../../utils/generateThumbnail";
import { newHarkState } from "../atoms/NewHarkStateAtom";

interface VideoPlaybackProps {
  duration: number;
  closeCamera: () => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onRetry: () => void;
}

const VideoPlayback = ({
  duration,
  closeCamera,
  onClose,
  isOpen,
  onOpen,
  onRetry,
}: VideoPlaybackProps) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);

  const vidRef = useRef(null);
  // const handlePlayVideo = () => {

  //   vidRef?.current?.play();
  // };

  const closeModal = () => {
    closeCamera();
    onClose();
  };

  const handleSaveClick = () => {
    generateThumbnail((blob) => {
      setNewHark({ ...newHark, thumbNail: blob });
    });
    closeModal();
  };

  return (
    <Modal
      size={["full", "md"]}
      isOpen={isOpen}
      onClose={closeModal}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">
          <HStack justify="space-between">
            <Text> Record Video Clip</Text>
            <Button
              onClick={onRetry}
              fontWeight="normal"
              width="fit-content"
              textAlign="right"
              variant="outline"
              leftIcon={<IoMdRefresh />}
            >
              Retry
            </Button>
          </HStack>
        </ModalHeader>

        <ModalBody>
          <Box>
            <VStack width="full">
              <figure>
                <video
                  style={{ maxHeight: "65vh" }}
                  ref={vidRef}
                  controls
                  playsInline
                >
                  {newHark.recording && (
                    <source
                      src={window.URL.createObjectURL(
                        newHark.recording?.source
                      )}
                      type="video/mp4"
                    />
                  )}
                </video>
              </figure>
              <Input
                type="text"
                placeholder="Enter Product Name Here"
                value={newHark.productName}
                onChange={(e) => {
                  setNewHark({ ...newHark, productName: e.target.value });
                }}
              />
            </VStack>
          </Box>
        </ModalBody>

        <ModalFooter justifyContent="space-between">
          <Text>{secondsToHH_MM_SS(duration)}</Text>
          <Button
            bg="brand.50"
            variant="solid"
            onClick={handleSaveClick}
            isDisabled={!newHark.productName}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VideoPlayback;

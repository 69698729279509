import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import { HiOutlinePlus } from "react-icons/hi";
import {
  Box,
  Button,
  Heading,
  HStack,
  Stack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  VStack,
  Wrap,
  Image,
  ButtonGroup,
} from "@chakra-ui/react";

import { loggedInUserState } from "../../atoms/UserAthomState";

import { IHark } from "../../@types/IHark";
import { pageRoutes } from "../../config/pageRoutes";
import { convertTZ, DD_Month_YYYY } from "../../utils/dateFormatter";
import { getHarks } from "../../api/hark";

import NoHarksSvg from "../../assets/svg/NoHarks.svg";
import StatusTag from "../../components/StatusTag";

export default function HarksPage() {
  const user = useRecoilValue(loggedInUserState);
  const [activeHarks, setActiveHarks] = useState<IHark[]>([]);
  const [, setLoading] = useState(false);

  const loadHarks = async () => {
    try {
      setLoading(true);
      const harks = await getHarks();
      setActiveHarks(harks);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    loadHarks();
  }, [user]);

  return (
    <Box
      width="full"
      height="full"
      px={{ base: "0", md: "16" }}
      display="flex"
      justifyContent="center"
      pt={5}
    >
      <Stack spacing={10} maxWidth="800px">
        <Box
          px="10"
          py="5"
          borderRadius="xl"
          w="100%"
          bgGradient="linear(to-bl, #ABF0E9, #57E2D4)"
          boxShadow="sm"
        >
          <Stack>
            <Heading size="md" mb="5">
              Get Started
            </Heading>
          </Stack>
          <Text fontSize="sm">
            Hark is a new channel for you to communicate with your favorite
            merchants for anything and everything related to your order!
          </Text>
          <HStack width="full" mt="5" justifyContent="end">
            <Button fontWeight="normal" variant="ghost">
              Dismiss
            </Button>
            <Button fontWeight="normal">Watch Video</Button>
          </HStack>
        </Box>

        <HStack justifyContent="space-between">
          <Heading color="primary.800">Library</Heading>
          <Link to="/new">
            <Button
              variant="solid"
              colorScheme="primary"
              bg="primary.600"
              _hover={{ bg: "primary.500" }}
              leftIcon={<HiOutlinePlus />}
            >
              Create New Hark
            </Button>
          </Link>
        </HStack>

        <Tabs variant="unstyled">
          <TabList>
            <Tab
              color="gray.500"
              _selected={{
                bg: "primary.50",
                color: "primary.700",
                borderBottom: "2px",
                borderBottomColor: "primary.700",
              }}
            >
              Active Harks
            </Tab>
            <Tab
              color="gray.500"
              _selected={{
                bg: "primary.50",
                color: "primary.700",
                borderBottom: "2px",
                borderBottomColor: "primary.700",
              }}
            >
              Resolved Harks
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {activeHarks.length > 0 ? (
                <Wrap>
                  {activeHarks.map((hark) => (
                    <Link
                      to={`${pageRoutes.HARKS}/${hark._id}`}
                      state={{ data: hark }}
                    >
                      <VStack p={[2, 5]} bg="white" rounded="md" boxShadow="xl">
                        <Image rounded="md" w="40" src={hark.thumbnail_url} />
                        <Box
                          alignItems="start"
                          gap="2"
                          width="full"
                          display="flex"
                        >
                          <Image h="5" src={hark.company.logo_url} />
                          <Stack>
                            <Text fontWeight="bold">{hark.product_name}</Text>
                            <Text fontWeight="light" fontSize="sm">
                              {DD_Month_YYYY(convertTZ(hark.createdAt))}
                            </Text>
                          </Stack>
                        </Box>
                        <Box width="full" display="flex" justifyContent="end">
                          <StatusTag status={hark.status} />
                        </Box>
                      </VStack>
                    </Link>
                  ))}
                </Wrap>
              ) : (
                <Center>
                  <VStack
                    mt="5"
                    maxWidth="400px"
                    spacing={5}
                    alignItems="center"
                  >
                    <img src={NoHarksSvg} alt="Hark logo" />
                    {!!user ? (
                      <Text>No Hark Found</Text>
                    ) : (
                      <Text fontWeight="bold">Create an account</Text>
                    )}
                    <Text color="gray.500" fontSize="sm">
                      {!!user
                        ? "Your search  did not match any projects. Please try again"
                        : "Create an account or login to keep track of your harks. It takes less than a minute."}
                    </Text>

                    {!!user ? (
                      <Button
                        variant="solid"
                        colorScheme="primary"
                        bg="primary.600"
                        _hover={{ bg: "primary.500" }}
                        leftIcon={<HiOutlinePlus />}
                        as={Link}
                        to="/new"
                      >
                        New Hark
                      </Button>
                    ) : (
                      <ButtonGroup gap="4">
                        <Button
                          as={Link}
                          state={{ isSignUp: true }}
                          to="/login"
                          variant="outline"
                          colorScheme="primary"
                        >
                          Signup
                        </Button>
                        <Button
                          bg="primary.600"
                          _hover={{ bg: "primary.500" }}
                          as={Link}
                          to="/login"
                          colorScheme="primary"
                        >
                          Login
                        </Button>
                      </ButtonGroup>
                    )}
                  </VStack>
                </Center>
              )}
            </TabPanel>
            <TabPanel>
              <p></p>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Box>
  );
}

import { useState } from "react";
import { IoMdAttach } from "react-icons/io";
import { HiOutlineCamera } from "react-icons/hi";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Text,
  Button,
  Stack,
  Heading,
  ButtonGroup,
  useDisclosure,
} from "@chakra-ui/react";

import { newHarkState } from "../atoms/NewHarkStateAtom";
import { autoDetectedCompanyState } from "../atoms/autoDetactedCompanyAtom";
import AttachFileModal from "./AttachFileModal";
import UploadedFile from "./UploadedFile";
import TakePhoto from "./TakePhoto";

const Attachment = () => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const { attachments } = newHark;
  const autoDetectedCompany = useRecoilValue(autoDetectedCompanyState);
  const metaData = autoDetectedCompany?.metaData;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenAttachmentModal,
    onOpen: onOpenAttachmentModal,
    onClose: onCloseAttachmentModal,
  } = useDisclosure();

  return (
    <Stack
      px={[5, 10]}
      py="8"
      bg="white"
      borderRadius="md"
      maxWidth="700px"
      shadow="md"
    >
      <Stack>
        <Heading size="sm">
          {metaData?.uploadTitle || "Upload Additional Documents"}
        </Heading>
        <Text color="GrayText" fontSize="sm">
          {metaData?.uploadMessage ||
            "Kindly upload any other document or file that will be help in resolving the Hark"}
        </Text>
      </Stack>
      <ButtonGroup size="sm" color="brand.500">
        <Button
          onClick={onOpen}
          leftIcon={<HiOutlineCamera color="gray" />}
          colorScheme="brand"
          variant="ghost"
        >
          Take picture
        </Button>
        <Button
          onClick={onOpenAttachmentModal}
          leftIcon={<IoMdAttach color="gray" />}
          colorScheme="brand"
          variant="ghost"
        >
          Attach file
        </Button>
      </ButtonGroup>

      <Stack px={[2, 5]} pt={[1, 2]} gap={2}>
        {attachments.map((file, index) => (
          <UploadedFile
            key={index}
            fileName={file.name}
            size={file.size}
            type={file.type}
            onDelete={() => {
              const files = [...attachments];
              files.splice(index, 1);
              setNewHark({ ...newHark, attachments: files });
            }}
          />
        ))}
      </Stack>

      <AttachFileModal
        onClose={onCloseAttachmentModal}
        isOpen={isOpenAttachmentModal}
        onOpen={onOpenAttachmentModal}
      />

      <TakePhoto isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </Stack>
  );
};

export default Attachment;

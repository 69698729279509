import { Text, Heading, Box } from "@chakra-ui/react";
import { ICompanyMetaData } from "../../../@types/ICompany";

const CompanyHeading = ({ headingTitle, headingMessage }: ICompanyMetaData) => {
  return !headingTitle && !headingMessage ? (
    <></>
  ) : (
    <Box>
      {headingTitle && (
        <Heading as="h1" size="lg">
          {headingTitle}
        </Heading>
      )}
      {headingMessage && (
        <Text as="h2" color="GrayText">
          {headingMessage}
        </Text>
      )}
    </Box>
  );
};

export default CompanyHeading;

import { useState } from "react";
import { useRecoilState } from "recoil";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

import { newHarkState } from "../atoms/NewHarkStateAtom";
import DragDropFileUploader from "./DragAndDrop.jsx";

const AttachFileModal = ({
  isOpen,
  onOpen,
  onClose,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const [attachments, setAttachments] = useState<any[]>([]);

  const handleChange = (file: any) => {
    setAttachments([...attachments, file]);
  };

  const handleDelete = (index: number) => {
    const files = [...attachments];
    files.splice(index, 1);
    setAttachments(files);
  };

  const onSave = () => {
    setNewHark({ ...newHark, attachments });
    onClose();
  };

  const onCancel = () => {
    setNewHark({ ...newHark, attachments: [] });
    setNewHark({ ...newHark, attachments: [] });
    onClose();
  };

  return (
    // @ts-ignore
    <Modal size="xl" isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <DragDropFileUploader
            onChange={handleChange}
            files={attachments}
            onDelete={handleDelete}
          />
        </ModalBody>

        <ModalFooter justifyContent="space-between">
          <Button variant="ghost" mr={3} onClick={onCancel}>
            cancel
          </Button>
          <Button
            _hover={{ bg: "brand.500", color: "buttonText" }}
            isDisabled={attachments.length === 0}
            onClick={onSave}
            bg="brand.50"
            color="brand.secondaryButtonText"
            variant="solid"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AttachFileModal;

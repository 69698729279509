import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";

interface LoginFormProps {
  onSubmit: (
    email: string,
    password: string,
    name: string
  ) => void | ((email: string, password: string) => void);
  loading: boolean;
  isSignUp: boolean;
  isDisabled: boolean;
}
export const LoginForm = ({
  onSubmit,
  loading,
  isSignUp,
  isDisabled,
}: LoginFormProps) => {
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
    name: "",
  });

  const handleChange = ({ target }: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = target as HTMLTextAreaElement;
    setLoginCredentials({ ...loginCredentials, [name]: value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(
      loginCredentials.email,
      loginCredentials.password,
      loginCredentials.name
    );
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <VStack gap={2} width="full">
        <Text color="GrayText">Please enter your details</Text>
        {/* sign up form */}

        {isSignUp && (
          <Input
            onChange={handleChange}
            name="name"
            type="text"
            placeholder="Enter your full name"
            size="lg"
            required
          />
        )}

        <Input
          onChange={handleChange}
          name="email"
          type="text"
          placeholder="Enter your email"
          size="lg"
          required
        />
        <Input
          onChange={handleChange}
          name="password"
          type="password"
          size="lg"
          required
        />
        {!isSignUp && (
          <HStack width="100%" justify="space-between" fontSize={["sm", "md"]}>
            <Box display="flex" gap="1" alignItems="center">
              <Checkbox /> Remember for 30 days
            </Box>
            <Link color="primary.600" fontWeight="bold">
              Forgot Password
            </Link>
          </HStack>
        )}
        <Button
          width="100%"
          variant="solid"
          colorScheme="primary"
          bg="primary.600"
          _hover={{ bg: "primary.500" }}
          size="lg"
          type="submit"
          isDisabled={isDisabled}
          isLoading={loading}
          loadingText={isSignUp ? "Signing up..." : "Signing in..."}
        >
          {isSignUp ? "Get Started" : "Sign In"}
        </Button>
      </VStack>
    </form>
  );
};

import Joi, { Schema } from "joi";
import { INewHark } from "../../../@types/IHark";

export const contactDetailsSchema: { [key: string]: Joi.Schema } = {
  firstName: Joi.string().required().label("LastName"),
  lastName: Joi.string().required().label("First Name"),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email"),
  phone: Joi.string()
    .min(4)
    .label("phone")
    .when("communicationMethod", {
      is: Joi.exist().valid("phone"),
      then: Joi.required(),
      otherwise: Joi.optional(),
    }),
  communicationMethod: Joi.valid("email", "phone")
    .required()
    .default("email")
    .label("Communication method"),
  address: Joi.string().required().optional().label("address"),
};

export const recordingSchema = Joi.object({
  type: Joi.valid("audio", "video", "screen").required(),
  source: Joi.any().required(),
  mediaType: Joi.valid("mp3", "webm", "mp4"),
  playbackUrl: Joi.string().optional(),
}).required();

export const newHarkObjectSchema = {
  company: Joi.string().required().label("Merchant"),
  contactDetails: Joi.object(contactDetailsSchema).required(),
  recording: recordingSchema.required().messages({
    "object.base": `** Recording is required **`,
  }),
  productName: Joi.string().required().label("Product Name"),
  thumbNail: Joi.any().optional(),
  attachments: Joi.array().items(Joi.any()).optional(),
};

export const validateNewHark = (newHark: INewHark) => {
  const newHarkSchema = Joi.object(newHarkObjectSchema);

  console.log("Validating hark...");
  const { error } = newHarkSchema.validate(newHark, { abortEarly: false });

  if (!error) return;

  const errors: { [key: string]: string } = {};

  error?.details.forEach((detail) => {
    const key = String(detail.context?.key);
    errors[key] = detail.message;
  });

  return errors;
};

export const validateItem = (schema: Schema, value: any) => {
  const { error } = schema.validate(value);
  return error?.message;
};

import { Box, Text, Button, Stack, HStack, Skeleton } from "@chakra-ui/react";
import { HiOutlineChevronDown } from "react-icons/hi";

import React, { useState } from "react";

export interface ICustomSelectOption {
  element: React.ReactNode;
  value: string;
}
interface CustomSelectProps {
  label: string;
  options: ICustomSelectOption[];
  onChange: (value: ICustomSelectOption | null) => void;
  value: ICustomSelectOption | null;
  loading?: boolean;
  isInvalid?: boolean;
}

const CustomSelect = ({
  label,
  options,
  onChange,
  value,
  loading,
  isInvalid = false,
}: CustomSelectProps): JSX.Element => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <Box
      borderRadius="5px"
      border={!showOptions && value ? "0" : "1px"}
      borderColor={isInvalid ? "red.500" : "gray.100"}
      minHeight="10"
      width="full"
      position="relative"
    >
      {!value && (
        <Box
          as="button"
          width="full"
          height="10"
          px="3"
          onClick={toggleOptions}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          outline={showOptions ? "1px solid blue" : ""}
          borderRadius="5px 5px 0 0"
        >
          <Text fontSize={showOptions ? "xx-small" : "md"} color="GrayText">
            {label}
          </Text>
          <HiOutlineChevronDown />
        </Box>
      )}

      {!showOptions && value && (
        <HStack>
          {value.element}
          <Button
            onClick={() => {
              onChange(null);
              setShowOptions(true);
            }}
            colorScheme="primary"
            variant="ghost"
            fontSize={["sm", "md"]}
          >
            Change
          </Button>
        </HStack>
      )}

      {/* options */}
      {showOptions && loading && (
        <Stack p="5">
          {new Array(5).fill(0).map(() => (
            <Skeleton height="12" startColor="primary.25" endColor="gray.200" />
          ))}
        </Stack>
      )}

      {showOptions && (
        <Stack
          px={["2", "5"]}
          position="absolute"
          width="full"
          background="white"
          zIndex={10}
          top={12}
          boxShadow="lg"
          maxH="60vh"
          overflowY="scroll"
          pb={[2, 5]}
          rounded="md"
        >
          {options.map((option, index) => (
            <Box
              onClick={() => {
                toggleOptions();
                onChange(option);
              }}
              width="full"
              key={index}
              value={option.value}
              as="button"
            >
              {option.element}
            </Box>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default CustomSelect;

// return a date string in the format DD MON YYYY
// accepts optional dateString. If dateString is not provided then today's date is used
export const DD_MON_YYYY = (dateString?: string) => {
  const date = dateString ? new Date(dateString) : new Date();
  return date
    .toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })
    .replace(/ /g, " ");
};

export function secondsToHH_MM_SS(totalSeconds: number) {
  let hours: number | string = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes: number | string = Math.floor(totalSeconds / 60);
  let seconds: number | string = totalSeconds % 60;

  minutes = String(minutes).padStart(2, "0");
  hours = String(hours).padStart(2, "0");
  seconds = String(seconds).padStart(2, "0");

  return totalSeconds < 3600
    ? `${minutes}: ${seconds}`
    : `${hours}:${minutes}: ${seconds}`;
}

export const DD_Month_YYYY = (date: Date) => {
  const hh = date.getHours(); // => 9
  const min = date.getMinutes();
  const minutes = min < 10 ? `0${min}` : min;
  const hours = hh < 10 ? `0${hh}` : hh;

  const formattedDate = date

    .toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })
    .replace(/ /g, "-");
  return `${formattedDate}, ${hours}:${minutes}`;
};

export const DdMonthYYYY = (date: Date) => {
  return date
    .toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })
    .replace(/ /g, "-");
};

export const HHMM = (date: Date) => {
  const hh = date.getHours(); // => 9
  const min = date.getMinutes();
  const minutes = min < 10 ? `0${min}` : min;
  const hours = hh < 10 ? `0${hh}` : hh;

  return `${hours}:${minutes}`;
};

export const convertTZ = (date: string, tzString?: string) => {
  if (!tzString) tzString = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return new Date(
    new Date(date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
};

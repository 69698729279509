import {
  Modal,
  ModalOverlay,
  ModalContent,
  Button,
  Box,
  HStack,
  IconButton,
  Stack,
} from "@chakra-ui/react";
import { useRef } from "react";
import { IoMdClose, IoMdRefresh } from "react-icons/io";
import { useRecoilState } from "recoil";

import { generateThumbnail } from "../../../utils/generateThumbnail";
import { newHarkState } from "../atoms/NewHarkStateAtom";

interface VideoPlaybackProps {
  duration: number;
  closeCamera: () => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onRetry: () => void;
}

const MobilePlaybackModal = ({
  closeCamera,
  onClose,
  isOpen,
  onRetry,
}: VideoPlaybackProps) => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);

  const vidRef = useRef(null);

  const closeModal = () => {
    closeCamera();
    onClose();
  };

  const handleCancelRecording = () => {
    setNewHark({
      ...newHark,
      thumbNail: undefined,
      recording: null,
    });
    closeCamera();
    onClose();
  };

  const handleSaveClick = () => {
    generateThumbnail((blob) => {
      setNewHark({
        ...newHark,
        thumbNail: blob,
        productName: `Video Recording - ${new Date().toDateString()}`,
      });
    });
    closeModal();
  };

  return (
    <Modal size={"full"} isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent
        background={"black"}
        color="white"
        height={"-webkit-fill-available"}
      >
        <Stack flexFlow="column" height="100%" py={5} gap={2}>
          <Box px={5}>
            <IconButton
              onClick={handleCancelRecording}
              aria-label="Discard"
              icon={<IoMdClose size={24} />}
              color="gray.300"
              variant={"ghost"}
              justifySelf={"left"}
            />
          </Box>

          <Box>
            <video ref={vidRef} playsInline controls>
              {newHark.recording && (
                <source
                  src={window.URL.createObjectURL(newHark.recording?.source)}
                  type="video/mp4"
                />
              )}
            </video>
          </Box>
          <HStack justify={"space-between"} px={5}>
            <Button
              onClick={onRetry}
              fontWeight="normal"
              width="fit-content"
              textAlign="right"
              variant="ghost"
              leftIcon={<IoMdRefresh />}
              pl={0}
            >
              Retry
            </Button>
            <Button
              variant="solid"
              color="brand.buttonText"
              colorScheme={"brand"}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </HStack>
        </Stack>
      </ModalContent>
    </Modal>
  );
};

export default MobilePlaybackModal;

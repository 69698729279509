// Library imports
import GoogleLogin from "react-google-login";
import { Button, Image } from "@chakra-ui/react";

// local imports
import googleLogo from "../../assets/svg/googleLogo.svg";

interface LoginWithGoogleProps {
  onError: (res: any) => void;
  onSuccess: (res: any) => void;
  loading: boolean;
  isDisabled: boolean;
  isSignUp: boolean;
}

const LoginWithGoogle = ({
  onError,
  onSuccess,
  loading,
  isDisabled,
  isSignUp,
}: LoginWithGoogleProps) => {
  return (
    <GoogleLogin
      clientId={
        process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ||
        "549274668534-rhj5fgtlj0bdqlek30q0p71mmd3qinjd.apps.googleusercontent.com"
      }
      render={(renderProps) => (
        <Button
          onClick={() => {
            renderProps.onClick();
          }}
          isDisabled={isDisabled}
          isLoading={loading}
          loadingText="Signing in..."
          variant="outline"
          width="100%"
          size="lg"
          colorScheme="gray"
          color="gray.700"
        >
          <Image src={googleLogo} mr="4" /> Sign {isSignUp ? "up" : "in"} with
          Google
        </Button>
      )}
      buttonText="Login"
      onSuccess={onSuccess}
      onFailure={onError}
      cookiePolicy={"single_host_origin"}
    />
  );
};

export default LoginWithGoogle;

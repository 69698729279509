import { Button, ButtonProps, Tooltip } from "@chakra-ui/react";

export const IconButton = ({ children, variant, ...rest }: ButtonProps) => (
  <Button
    type="submit"
    bg="brand.50"
    color="brand.700"
    fontWeight="medium"
    borderRadius="full"
    padding="unset"
    {...rest}
  >
    {children}
  </Button>
);

interface ToolTipProps extends ButtonProps {
  children: React.ReactNode;
  label: string;
  onClick: () => void;
}
const ToolTipButton = ({ children, label, ...rest }: ToolTipProps) => {
  return (
    <Tooltip
      color="gray.700"
      hasArrow
      label={label}
      bg="white"
      borderRadius="lg"
      boxShadow="lg"
      placement="top"
      p="2"
    >
      <Button
        type="submit"
        bg="brand.50"
        color="brand.secondaryButtonText"
        fontWeight="medium"
        borderRadius="full"
        padding="unset"
        {...rest}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default ToolTipButton;

import { Button, useDisclosure, Wrap } from "@chakra-ui/react";
import { FiMic } from "react-icons/fi";
import RecordVideo from "./RecordVideo/RecordVideoModal";
import { useRecoilValue } from "recoil";
import { newHarkState } from "./atoms/NewHarkStateAtom";
import RecordScreen from "./RecordScreen/RecordScreenModal";
import { autoDetectedCompanyState } from "./atoms/autoDetactedCompanyAtom";
interface RecordingOptionsProp {
  startAudioRecording: () => void;
}
const RecordingOptions = ({ startAudioRecording }: RecordingOptionsProp) => {
  const newHark = useRecoilValue(newHarkState);
  const autoDetectedCompany = useRecoilValue(autoDetectedCompanyState);
  const harkOptions = autoDetectedCompany?.settings.harkOptions;

  const isDisabled = Boolean(newHark.recording);

  return (
    <Wrap justifyContent="space-between">
      {/* Record Video */}
      {(harkOptions?.video || !autoDetectedCompany) && <RecordVideo />}

      {/* Record Audio */}
      {(harkOptions?.audio || !autoDetectedCompany) && (
        <Button
          isDisabled={isDisabled}
          onClick={startAudioRecording}
          leftIcon={<FiMic />}
          variant="outline"
        >
          Record Audio
        </Button>
      )}

      {/* Record Screen */}
      {(harkOptions?.screen || !autoDetectedCompany) && <RecordScreen />}
    </Wrap>
  );
};

export default RecordingOptions;

import { Input, Box, Text, InputProps } from "@chakra-ui/react";
import { useState } from "react";

interface CustomInputProps extends InputProps {
  label: string;
}

const CustomInput = ({ label, ...rest }: CustomInputProps): JSX.Element => {
  const [showLabel, setShowLabel] = useState<boolean>(false);

  return (
    <Box position="relative">
      {showLabel && (
        <Text
          fontSize="xx-small"
          color="GrayText"
          position="absolute"
          px="4"
          pt="1"
        >
          {label}
        </Text>
      )}
      <Input
        onFocus={() => setShowLabel(true)}
        onBlur={(e) => setShowLabel(!!e.target.value)}
        paddingTop={showLabel ? "4" : "0"}
        paddingBottom={showLabel ? "1" : "0"}
        placeholder={showLabel ? "" : label}
        fontSize="sm"
        {...rest}
      />
    </Box>
  );
};

export default CustomInput;

import { Flex, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { IoMdClose, IoMdCheckmark } from "react-icons/io";
import { GiSoundWaves } from "react-icons/gi";
import { IconButton } from "../TooltipButton";
import { secondsToHH_MM_SS } from "../../../utils/dateFormatter";

interface AudioRecorderProps {
  audioDuration: number;
  audioVolumePercentage: number;
  stopAudioRecording: () => void;
}

const AudioRecorder = ({
  audioDuration,
  audioVolumePercentage,
  stopAudioRecording,
}: AudioRecorderProps) => {
  return (
    <Stack gap="2" bg="white" overflow="hidden">
      <HStack gap="2">
        <HStack width="100%" height={16} gap={0} overflow="hidden">
          {new Array(20).fill(0).map((_, index) => (
            <Icon
              minH={4}
              minW={4}
              key={index}
              w={(((index % 10) + 1) * 16 * audioVolumePercentage) / 100}
              h={(((index % 10) + 1) * 16 * audioVolumePercentage) / 100}
              as={GiSoundWaves}
              color="brand.200"
            />
          ))}
        </HStack>
        <Text w="10" textAlign="right" fontSize="xs">
          {secondsToHH_MM_SS(audioDuration)}
        </Text>
      </HStack>
      <Flex gap={2}>
        <IconButton onClick={stopAudioRecording}>
          <IoMdClose />
        </IconButton>
        <IconButton onClick={stopAudioRecording}>
          <IoMdCheckmark />
        </IconButton>
      </Flex>
    </Stack>
  );
};

export default AudioRecorder;

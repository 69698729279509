import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Text,
  HStack,
  Spinner,
  Center,
  Stack,
  IconButton,
} from "@chakra-ui/react";
import { IoMdClose, IoMdReverseCamera } from "react-icons/io";
import { secondsToHH_MM_SS } from "../../../utils/dateFormatter";
import { RecordButton } from "./RecordButton";

interface MobileModalProps {
  loading: boolean;
  isOpen: boolean;
  onClose: () => void;
  videoStream: MediaStream | null;
  startRecording: () => Promise<void>;
  isRecording: boolean;
  handleStopRecording: () => void;
  duration: number;
  reverseCameraFacing: () => void;
  handleCancelRecording: () => void;
}

export const MobileRecordModal = ({
  loading,
  isOpen,
  onClose,
  videoStream,
  startRecording,
  isRecording,
  handleStopRecording,
  duration,
  reverseCameraFacing,
  handleCancelRecording,
}: MobileModalProps) => {
  return (
    <Modal
      size={"full"}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        background={"black"}
        minH={"-webkit-fill-available"}
        height={"-webkit-fill-available"}
      >
        <Box position="relative" height={"full"}>
          {loading && (
            <Center height={"full"} width={"full"} paddingY={20}>
              <Spinner
                thickness="5px"
                speed="1s"
                emptyColor="gray.200"
                color="brand.100"
                size="xl"
              />
            </Center>
          )}

          <Box display="flex" width="full" height="full">
            <video
              id={"record-video"}
              playsInline
              muted
              autoPlay
              style={{
                minWidth: "100%",
                minHeight: "100%",
                overflow: "auto",
                position: "fixed",
                right: 0,
                bottom: 0,
              }}
            ></video>
          </Box>

          <Stack
            width="full"
            position="fixed"
            bottom={10}
            gap={2}
            zIndex={1000}
          >
            <Text textAlign={"center"} color="white">
              {secondsToHH_MM_SS(duration)}
            </Text>

            <HStack
              width={"full"}
              justify="space-evenly"
              height={"fit-content"}
            >
              <IconButton
                onClick={handleCancelRecording}
                colorScheme="gray"
                aria-label="Discard"
                rounded="full"
                icon={<IoMdClose />}
                background="rgba(255, 255, 255, 0.25)"
                color="white"
                size="md"
              />

              <RecordButton
                isRecording={isRecording}
                isDisabled={!videoStream}
                onClick={isRecording ? handleStopRecording : startRecording}
              />
              <IconButton
                onClick={reverseCameraFacing}
                colorScheme="gray"
                aria-label="Discard"
                rounded="full"
                icon={<IoMdReverseCamera />}
                background="rgba(255, 255, 255, 0.25)"
                color="white"
                size="md"
              />
            </HStack>
          </Stack>
        </Box>
      </ModalContent>
    </Modal>
  );
};

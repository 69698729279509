import { useRecoilValue } from "recoil";
import { brandColorsThemeState } from "../../atoms/brandColorsThemeState";
import { autoDetectedCompanyState } from "./atoms/autoDetactedCompanyAtom";

function HarkSentIcon() {
  const brandColours = useRecoilValue(brandColorsThemeState);
  const autoDetectedCompany = useRecoilValue(autoDetectedCompanyState);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="83"
      height="75"
      fill="none"
      viewBox="0 0 83 75"
    >
      <circle
        cx="59"
        cy="51"
        r="24"
        fill={autoDetectedCompany ? brandColours[25] : "#ECFBF9"}
      ></circle>
      <circle
        cx="18"
        cy="12"
        r="12"
        fill={autoDetectedCompany ? brandColours[50] : "#DAF7F4"}
      ></circle>
      <circle cx="73" cy="16" r="4" fill={brandColours[300]}></circle>
      <path
        stroke={brandColours[500]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M65 34.16V36a20 20 0 11-11.86-18.28M65 20L45 40.02l-6-6"
      ></path>
      <circle cx="24" cy="64" r="4" stroke={brandColours[300]}></circle>
      <path
        stroke={autoDetectedCompany ? brandColours[100] : "#C9F2EE"}
        d="M9.606 37.535l-2.242 8.366-6.123-6.124 8.365-2.242z"
      ></path>
    </svg>
  );
}

export default HarkSentIcon;

import axios, { AxiosRequestConfig } from "axios";
import { IHark, INewHark } from "../@types/IHark";

const BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  "https://second-caster-340917.uc.r.appspot.com/api/v1";

export async function createHark(newHark: INewHark) {
  const { firstName, lastName, email, phone, communicationMethod, address } =
    newHark.contactDetails!;

  const recording = await uploadFileToCloudStorage(
    newHark.recording?.source as Blob
  );
  const thumbnail = await uploadFileToCloudStorage(newHark.thumbNail!);

  const additional_files: Array<{ objectId: string; name: string }> =
    await Promise.all(
      newHark.attachments.map(async (attachment, index) => {
        const objectId: string = await uploadFileToCloudStorage(attachment);
        return { objectId, name: `additional_file_${index}` };
      })
    );

  const config: AxiosRequestConfig = {
    method: "POST",
    url: `${BASE_URL}/tasks`,

    data: {
      recording,
      thumbnail,
      name: `${firstName} ${lastName}`,
      email,
      phone,
      communication_method: communicationMethod,
      company: newHark.company,
      product_name: newHark.productName,
      additional_files,
      address,
    },
  };

  const harkToken = localStorage.getItem("hark-token");
  if (!!harkToken) config.headers = { Authorization: `Bearer ${harkToken}` };

  const response = await axios(config);
  return response.data;
}

export const getHarks = async (): Promise<IHark[]> => {
  const response = await axios.get(`${BASE_URL}/tasks`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("hark-token")}` },
  });
  return response.data.data;
};

export const getHarkById = async (id: string | number): Promise<IHark> => {
  const response = await axios.get(`${BASE_URL}/tasks/${id}`);
  return response.data.data;
};

async function uploadFileToCloudStorage(file: Blob): Promise<string> {
  const type = file.type.split("/")[1];
  const { _fileName, signedUrl } = await getCloudStorageSignedUrl(
    `file.${type}`
  );
  const config = {
    method: "PUT",
    url: signedUrl,
    headers: {
      "Content-Type": "application/octet-stream",
    },
    data: file,
  };

  await axios(config);
  return _fileName;
}

export async function getCloudStorageSignedUrl(
  fileName: string
): Promise<{ signedUrl: string; _fileName: string }> {
  const response = await axios.get(
    `${BASE_URL}/tasks/generate-presigned-url?fileName=${fileName}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("hark-token")}`,
      },
    }
  );
  return response.data.data;
}

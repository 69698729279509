export const updateFavicon = (href: string) => {
  let favicon: HTMLLinkElement | null =
    document.querySelector("link[rel~='icon']");
  let appleTouchIcon: HTMLLinkElement | null = document.querySelector(
    "link[rel~='apple-touch-icon']"
  );
  if (!favicon) {
    favicon = document.createElement("link");
    favicon.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(favicon);
  }

  if (!appleTouchIcon) {
    appleTouchIcon = document.createElement("link");
    appleTouchIcon.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(appleTouchIcon);
  }

  favicon.href = href;
  appleTouchIcon.href = href;
};

// Library imports
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import {
  Box,
  Button,
  Heading,
  Image,
  ScaleFade,
  useDisclosure,
  VStack,
  useToast,
} from "@chakra-ui/react";

// local imports
import harkLogoIcon from "../../assets/svg/harkLogoIcon.svg";
import { googleLogin, emailLogin, signUp } from "../../api/auth";
import { loggedInUserState } from "../../atoms/UserAthomState";
import GoogleLogin from "./GoogleLogin";
import { LoginForm } from "./LoginForm";

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { from?: string; isSignUp?: boolean };
  const toast = useToast();
  const setUser = useSetRecoilState(loggedInUserState);
  const [errMessage, setErrMessage] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [loadingGoogleLogin, setLoadingGoogleLogin] = useState(false);
  const [isSignUp, setIsSignUp] = useState(!!state?.isSignUp);
  const from = state?.from || "/";

  const toggleSignUp = () => {
    setIsSignUp(!isSignUp);
  };

  const onLoginSuccess = ({ payload }: any) => {
    const user = {
      email: payload.email,
      firstNme: payload.first_name,
      lastName: payload.last_name,
      photo: payload.photo_url,
    };
    setUser(user);
    localStorage.setItem("hark-token", payload.token);
    // Todo! Get User form API
    localStorage.setItem("hark-user", JSON.stringify(user));
    // Redirect the user to where he came from
    navigate(from, { replace: true });
  };

  const onLoginFailure = (err: any) => {
    setLoading(false);
    localStorage.removeItem("hark-token");
    localStorage.removeItem("hark-user");
    const errorMessage =
      err.response?.data?.message ||
      err.message ||
      "Processing Error. Contact support";
    setErrMessage(errorMessage);
    onOpen();
    setTimeout(() => {
      setErrMessage("");
      onClose();
    }, 4000);
  };

  const handleLoginWithEmail = (email: string, password: string) => {
    setLoading(true);
    emailLogin(email, password)
      .then((data) => {
        setLoading(false);
        toast({
          title: "Login Successful.",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
          variant: "subtle",
        });
        onLoginSuccess(data);
      })
      .catch(onLoginFailure);
  };

  const handleSignUp = (email: string, password: string, name: string) => {
    setLoading(true);
    signUp(email, password, name)
      .then(({ payload }: any) => {
        setLoading(false);
        toast({
          title: "Registration Successful.",
          description: "Confirm your email to login",
          duration: 10000,
          status: "success",
          isClosable: true,
          position: "top-right",
          variant: "subtle",
        });

        setIsSignUp(false);
        onLoginSuccess({ payload });
      })
      .catch(onLoginFailure);
  };

  const handleGoogleLoginSuccess = (googleResponse: any) => {
    setLoading(true);
    const idToken: string = googleResponse.tokenId;
    googleLogin(idToken)
      .then((data) => {
        setLoadingGoogleLogin(false);
        onLoginSuccess(data);
      })
      .catch(onLoginFailure);
  };

  const handleGoogleLoginFailure = ({ error }: any) => {
    console.log(error);
    setErrMessage(
      `Error signing in with google: ${error.replaceAll("_", " ")}`
    );
    onOpen();

    setTimeout(() => {
      setErrMessage("");
      onClose();
    }, 4000);
  };

  return localStorage.getItem("hark-token") ? (
    <Navigate to="/" replace={true} />
  ) : (
    <VStack minH="100vh" width="100%" bg="primary.25" px="5" pt={[8, 20]}>
      <VStack
        width={["100%", "440px"]}
        bg="white"
        boxShadow="lg"
        borderRadius="lg"
        p={[8, 12]}
        gap="2"
      >
        <ScaleFade initialScale={0.9} in={isOpen}>
          {errMessage && (
            <Box
              border="1px"
              borderColor="red.100"
              rounded="md"
              bg="red.50"
              fontSize="sm"
              color="red.600"
              px={2}
              py={1}
            >
              Error: {errMessage}
            </Box>
          )}
        </ScaleFade>
        {/* Heading */}
        <Image src={harkLogoIcon} />
        <Heading>{isSignUp ? "Start your free trial" : "Welcome Back"}</Heading>
        <LoginForm
          onSubmit={isSignUp ? handleSignUp : handleLoginWithEmail}
          loading={loading}
          isSignUp={isSignUp}
          isDisabled={loading || loadingGoogleLogin}
        />
        {/* Google Sign In */}
        <GoogleLogin
          loading={loadingGoogleLogin}
          onSuccess={handleGoogleLoginSuccess}
          onError={handleGoogleLoginFailure}
          isDisabled={loading || loadingGoogleLogin}
          isSignUp={isSignUp}
        />
        <Box display="flex">
          {isSignUp ? "Already " : "Don't "} have an account? &nbsp;
          <Button
            onClick={toggleSignUp}
            variant="link"
            fontWeight="bold"
            color="primary.600"
          >
            {isSignUp ? "Login" : "Sign up"}
          </Button>
        </Box>
      </VStack>
    </VStack>
  );
};

export default LoginPage;

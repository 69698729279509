import { Button, useDisclosure } from "@chakra-ui/react";
import { FiVideo } from "react-icons/fi";
import { useRecoilState } from "recoil";
import { isMobileDevice } from "../../../utils/detectMobileBrowser";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import useVideoRecorder from "../hooks/useVideoRecorder";
import { DesktopRecordModal } from "./DesktopRecordModal";
import MobilePlaybackModal from "./MobilePlayback";
import { MobileRecordModal } from "./MobileRecordModal";
import VideoPlayback from "./VideoPlayBack";

const RecordVideo = () => {
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isVideoPlayBackOpen,
    onOpen: openVideoPlayback,
    onClose: closeVideoPlayback,
  } = useDisclosure();

  const {
    videoStream,
    requestAccessToMediaDevices,
    closeCamera,
    startRecording,
    stopRecording,
    duration,
    isRecording,
    cameras,
    changeCamera,
    reverseCameraFacing,
    loadingCams,
    recordedDuration,
  } = useVideoRecorder();

  const openModal = () => {
    onOpen();
    requestAccessToMediaDevices().then(() => {});
  };

  const closeModal = () => {
    closeCamera();
    onClose();
  };

  const handleStopRecording = () => {
    stopRecording();
    openVideoPlayback();
    onClose();
  };

  const retryRecording = () => {
    setNewHark({ ...newHark, recording: null });
    closeVideoPlayback();
    openModal();
  };

  const handleCancelRecording = () => {
    stopRecording();
    closeCamera();

    setTimeout(() => {
      setNewHark({
        ...newHark,
        thumbNail: undefined,
        recording: null,
      });

      onClose();
    }, 50);
  };

  return (
    <>
      <Button
        isDisabled={Boolean(newHark.recording)}
        onClick={openModal}
        leftIcon={<FiVideo />}
        colorScheme="brand"
      >
        Record Video
      </Button>

      {newHark.recording ? (
        isMobileDevice() ? (
          <MobilePlaybackModal
            duration={duration}
            closeCamera={closeCamera}
            isOpen={isVideoPlayBackOpen}
            onOpen={openVideoPlayback}
            onClose={closeVideoPlayback}
            onRetry={retryRecording}
          />
        ) : (
          <VideoPlayback
            duration={recordedDuration}
            closeCamera={closeCamera}
            isOpen={isVideoPlayBackOpen}
            onOpen={openVideoPlayback}
            onClose={closeVideoPlayback}
            onRetry={retryRecording}
          />
        )
      ) : isMobileDevice() ? (
        <MobileRecordModal
          isOpen={isOpen}
          onClose={closeModal}
          loading={loadingCams}
          startRecording={startRecording}
          isRecording={isRecording}
          videoStream={videoStream}
          handleStopRecording={handleStopRecording}
          duration={duration}
          reverseCameraFacing={reverseCameraFacing}
          handleCancelRecording={handleCancelRecording}
        />
      ) : (
        <DesktopRecordModal
          isOpen={isOpen}
          onClose={closeModal}
          loading={loadingCams}
          startRecording={startRecording}
          isRecording={isRecording}
          videoStream={videoStream}
          changeCamera={changeCamera}
          cameras={cameras}
          handleStopRecording={handleStopRecording}
          duration={duration}
        />
      )}
    </>
  );
};

export default RecordVideo;

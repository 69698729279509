// library imports
import { ReactNode, useEffect } from "react";
import {
  Outlet,
  useNavigate,
  Link as HrefLink,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { IconType } from "react-icons";
import { HiOutlineSupport, HiMenuAlt1 } from "react-icons/hi";
import {
  RiCheckboxMultipleLine,
  RiNotification3Line,
  RiSettings5Line,
  RiHome6Line,
  RiSearchLine,
} from "react-icons/ri";
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Circle,
  Text,
  Button,
} from "@chakra-ui/react";

// local imports
import LogoName from "../../assets/svg/HarkLogoWide.svg";
import { loggedInUserState } from "../../atoms/UserAthomState";

interface LinkItemProps {
  name: string;
  icon: IconType;
  path: string;
}
const LinkItems: Array<LinkItemProps> = [
  { name: "Your Harks", icon: RiCheckboxMultipleLine, path: "/" },
  { name: "Notifications", icon: RiHome6Line, path: "/notifications" },
  { name: "Support", icon: HiOutlineSupport, path: "/support" },
  { name: "Settings", icon: RiSettings5Line, path: "/settings" },
];

export default function SidebarWithHeader() {
  const [searchParams] = useSearchParams();
  const is_embed = searchParams.get("is_embed") === "true";
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useRecoilState(loggedInUserState);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const logout = () => {
    localStorage.removeItem("hark-token");
    localStorage.removeItem("hark-user");
    setUser(null);
    navigate("/login", { state: { from: location } });
  };

  const loadUser = () => {
    if (user) return;
    const savedUser = localStorage.getItem("hark-user");
    if (!savedUser) return;
    try {
      const user = JSON.parse(savedUser);
      setUser(user);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadUser();
  }, []);
  return (
    <Box minH="100vh" bg="primary.25">
      {!is_embed && !!user && (
        <>
          <SidebarContent
            onClose={() => onClose}
            display={{ base: "none", md: "block" }}
          />
          <Drawer
            size="xs"
            autoFocus={false}
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={onClose}
          >
            <DrawerContent>
              <SidebarContent onClose={onClose} />
            </DrawerContent>
          </Drawer>
          {/* mobilenav */}
          <MobileNav onOpen={onOpen} logout={logout} />
        </>
      )}
      <Box ml={{ base: 0, md: is_embed || !user ? 0 : 60 }}>
        <Outlet />
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Link href="/">
          <img src={LogoName} alt="Hark logo" />
        </Link>
      </Flex>
      <VStack height="600" spacing={1} align="stretch" pb="5">
        {LinkItems.map((link, index) => (
          <>
            {index === 2 && <Spacer key={index} />}
            <NavItem key={link.name} icon={link.icon} path={link.path}>
              {link.name}
            </NavItem>
          </>
        ))}
      </VStack>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactNode;
  path: string;
}
const NavItem = ({ icon, path, children, ...rest }: NavItemProps) => {
  return (
    <Link
      href={path}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        px="4"
        py="2"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "gray.100",
        }}
        {...rest}
      >
        {icon && <Icon mr="4" fontSize="16" as={icon} />}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
  logout: () => void;
}
const MobileNav = ({ onOpen, logout, ...rest }: MobileProps) => {
  const user = useRecoilValue(loggedInUserState);
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="16"
      alignItems="center"
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        bg="white"
        size="sm"
        variant="solid"
        aria-label="open menu"
        icon={<HiMenuAlt1 size={20} />}
      />

      <HStack spacing={{ base: "1", md: "2" }}>
        <IconButton
          size="md"
          bg="white"
          variant="ghost"
          aria-label="open menu"
          icon={<RiSearchLine />}
        />
        <IconButton
          size="md"
          bg="white"
          variant="ghost"
          aria-label="open menu"
          icon={<RiNotification3Line />}
        />
        {user ? (
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              {user.photo ? (
                <Avatar
                  aria-label={user.firstNme}
                  size={"sm"}
                  src={user.photo}
                />
              ) : (
                <Circle size="30px" bg="gray.500" color="white">
                  <Text fontWeight="bold">
                    {user.firstNme.charAt(0).toLocaleUpperCase()}
                  </Text>
                </Circle>
              )}
            </MenuButton>
            <MenuList bg="white" borderColor="gray.200">
              <MenuItem>Profile</MenuItem>
              <MenuDivider />
              <MenuItem onClick={logout}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        ) : (
          <Button fontWeight="normal" to="/login" as={HrefLink}>
            Login
          </Button>
        )}
      </HStack>
    </Flex>
  );
};

import axios from "axios";
import { ICompany, ICompanyDetails } from "../@types/ICompany";

const BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  "https://second-caster-340917.uc.r.appspot.com/api/v1";

// TODO: use useCallback or useMemo or reactquery to prevent recalling the api to fecth companies
export async function getCompanies(): Promise<{ companies: ICompany[] }> {
  const response = await axios.get(`${BASE_URL}/companies`);
  return response.data.data;
}

export async function getCompanyById(
  id: string | number
): Promise<{ company: ICompanyDetails }> {
  const response = await axios.get(`${BASE_URL}/companies/${id}`);
  return response.data.data;
}

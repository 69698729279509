import { atom } from "recoil";

const brandColors = {
  25: "#F6FAFE",
  50: "#E8F1FE",
  100: "#A4C8F8",
  200: "#A4C8F8",
  300: "#649EF5",
  400: "#4083F4",
  450: "#2E69DA",
  500: "#02438F",
  700: "#023877",
  800: "#012D5F",
  900: "#011630",
  buttonText: "white",
  secondaryButtonText: "#012D5F",
};

export const brandColorsThemeState = atom({
  key: "brandColorsThemeState", // unique ID (with respect to other atoms/selectors)
  default: brandColors, // default value (aka initial value)
});

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Text,
  HStack,
  Stack,
  Spinner,
  Center,
  ScaleFade,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { FiMonitor } from "react-icons/fi";
import { useRecoilState } from "recoil";
import { secondsToHH_MM_SS } from "../../../utils/dateFormatter";
import { isMobileDevice } from "../../../utils/detectMobileBrowser";
import { newHarkState } from "../atoms/NewHarkStateAtom";
import { recordingCompleteState } from "../atoms/RecordingStateAtom";
import useScreenRecorder from "../hooks/useScreenRecorder";
import ScreenPlayback from "./ScreenPlayBack";
let hasUsedVideoStream = false;

const RecordScreen = () => {
  const recordingComplete = useRecoilState(recordingCompleteState);
  const [newHark, setNewHark] = useRecoilState(newHarkState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loadingCams, setLoadingCams] = useState(false);
  const [errMessage, setErrorMessage] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const {
    isOpen: openError,
    onOpen: onOpenError,
    onClose: onCloseError,
  } = useDisclosure();

  const {
    isOpen: isVideoPlayBackOpen,
    onOpen: openVideoPlayback,
    onClose: closeVideoPlayback,
  } = useDisclosure();

  const {
    videoStream,
    requestAccessToMediaDevices,
    closeCamera,
    startRecording,
    stopRecording,
    duration,
    isRecording,
  } = useScreenRecorder();

  const vidRef = useRef(null);
  // const handlePlayVideo = () => {

  //   vidRef?.current?.play();
  // };

  const openModal = () => {
    setErrorMessage("");
    onCloseError();

    if (isMobileDevice()) {
      setIsMobile(true);
      onOpen();
      return null;
    }

    setLoadingCams(true);
    onOpen();
    requestAccessToMediaDevices()
      .then(() => {
        setLoadingCams(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingCams(false);
        onOpenError();
        if (
          String(err.message)
            .toLocaleLowerCase()
            .includes("permission denied by system")
        )
          setErrorMessage(
            "Can't share screen. Check your system preferences to make sure your browser has screen recording permission, and try again"
          );
        else
          setErrorMessage("Please select a screen to begin screen Recording");

        // setErrorMessage(err);
      });
  };

  const closeModal = () => {
    hasUsedVideoStream = false;
    closeCamera();
    onClose();
  };

  const handleStopRecording = () => {
    stopRecording();
    openVideoPlayback();
    onClose();
  };

  const retryRecroding = () => {
    setNewHark({ ...newHark, recording: null });
    hasUsedVideoStream = false;
    closeVideoPlayback();
    openModal();
  };

  return (
    <>
      <Button
        isDisabled={Boolean(newHark.recording)}
        onClick={openModal}
        leftIcon={<FiMonitor />}
        variant="ghost"
        colorScheme={"brand"}
        color="brand.500"
      >
        Record Screen
      </Button>

      {newHark.recording ? (
        <ScreenPlayback
          duration={duration}
          closeCamera={closeCamera}
          isOpen={isVideoPlayBackOpen}
          onOpen={openVideoPlayback}
          onClose={closeVideoPlayback}
          onRetry={retryRecroding}
        />
      ) : (
        <Modal
          isOpen={isOpen}
          onClose={closeModal}
          closeOnOverlayClick={false}
          size="2xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize="md">
              <HStack justify="space-between">
                <Text> Record Screen</Text>
              </HStack>
            </ModalHeader>
            <ModalCloseButton size="sm" isDisabled={loadingCams} />
            {isMobile ? (
              <ModalBody>
                <Text textAlign="center">
                  Screen Recording is not supported on your device
                </Text>
              </ModalBody>
            ) : (
              <ModalBody>
                <ScaleFade initialScale={0.9} in={openError}>
                  {errMessage && (
                    <Stack>
                      {" "}
                      <Box
                        border="1px"
                        borderColor="red.100"
                        rounded="md"
                        bg="red.50"
                        fontSize="sm"
                        color="red.500"
                        px={2}
                        py={1}
                      >
                        {errMessage}
                      </Box>
                      <Button width="full" onClick={openModal}>
                        Try Again
                      </Button>
                    </Stack>
                  )}
                </ScaleFade>
                {loadingCams ? (
                  <Center paddingY={20}>
                    <Spinner
                      thickness="5px"
                      speed="1s"
                      emptyColor="gray.200"
                      color="brand.100"
                      size="xl"
                    />
                  </Center>
                ) : (
                  <Box>
                    <video
                      playsInline
                      ref={(video) => {
                        if (video && videoStream && !hasUsedVideoStream) {
                          hasUsedVideoStream = true;
                          video.srcObject = videoStream;
                        }
                      }}
                      muted
                      autoPlay
                    ></video>
                  </Box>
                )}
              </ModalBody>
            )}

            <ModalFooter>
              {isRecording ? (
                <HStack width="full" justify="space-between">
                  <Text>{secondsToHH_MM_SS(duration)}</Text>
                  <Box display="flex">
                    <Button
                      variant="outline"
                      colorScheme="gray"
                      mr={3}
                      onClick={closeModal}
                      isDisabled={!videoStream}
                    >
                      Pause
                    </Button>
                    {/* TODO:  make colorscheme or custom button for this button with stye*/}
                    <Button
                      isDisabled={!videoStream}
                      _hover={{ bg: "brand.100" }}
                      bg="brand.50"
                      variant="solid"
                      onClick={handleStopRecording}
                    >
                      Stop
                    </Button>
                  </Box>
                </HStack>
              ) : (
                <>
                  <Button
                    variant="outline"
                    colorScheme="gray"
                    mr={3}
                    onClick={closeModal}
                    isDisabled={loadingCams}
                  >
                    Cancel
                  </Button>
                  {/* TODO:  make colorscheme or custom button for this button with stye*/}
                  <Button
                    isDisabled={!videoStream}
                    _hover={{ bg: "brand.100" }}
                    bg="brand.50"
                    variant="solid"
                    onClick={startRecording}
                  >
                    Record
                  </Button>
                </>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default RecordScreen;
